import { getDomainByRegionId, isSameRegion, Regions } from '@jtnews/shared/seedwork/bff/domain';
import GoogleNewsIcon from 'jtn-ui/svg/social/google-news.svg';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiPromoBlock } from '@jtnews/jtn-ui';
import { ObserverVisibility, tsStoreMixin } from '@jtnews/shared';

import {
  GOOGLE_NEWS_MAP,
  GOOGLE_NEWS_MATCH_STRING,
  REFERRER_BASE_LINK,
  REFERRER_BLOCK_ALLOWED_REGIONS,
  RG_NAMES_MAP,
  YANDEX_NEWS_MAP,
  YANDEX_NEWS_MATCH_STRING,
  YANDEX_NEWS_UTM_MATCH_STRING,
  DZEN_MAP,
  DZEN_MATCH_STRING
} from './configs';
import styles from './subscriptions.style.scss?module';

type ComponentData = {
  subscriptionObsVisibility: ObserverVisibility;
  isShowReferredBlock: boolean;
  userEmail: string;
  referrerType: string;
};

const SUBSCRIPTION_PAGE_LINK = '/subscription/';
const YN_BLOCK_TYPE = 'yandexNews';
const DZEN_BLOCK_TYPE = 'dzen';
const GN_BLOCK_TYPE = 'googleNews';

const ICON_SIZE = 48;

export default componentFactory.mixin(tsStoreMixin).create({
  name: 'Subscriptions',
  props: {
    position: {
      type: String,
      default: ''
    }
  },
  data(): ComponentData {
    return {
      subscriptionObsVisibility: new ObserverVisibility('half'),
      isShowReferredBlock: false,
      userEmail: '',
      referrerType: ''
    };
  },
  computed: {
    regionId(): Regions {
      return this.store.regionId;
    },
    isE1(): boolean {
      return isSameRegion(this.regionId, Regions.Yekaterinburg);
    },
    referrerLink(): string {
      let linkMap = {};
      switch (this.type) {
        case 'yandexNews':
          linkMap = YANDEX_NEWS_MAP;
          break;
        case 'googleNews':
          linkMap = GOOGLE_NEWS_MAP;
          break;
        case 'dzen':
          linkMap = DZEN_MAP;
          break;
        default:
          break;
      }

      const linkSuffix = String(linkMap[this.regionId]) ?? '';
      const referrerBaseLink = String(REFERRER_BASE_LINK[this.type]) ?? '';
      return linkSuffix ? `${referrerBaseLink}${linkSuffix}` : '';
    },
    referrerLinkText(): string {
      return this.type === 'dzen' ? 'Подписаться' : 'Читать';
    },
    siteName(): string {
      return getDomainByRegionId(this.regionId).toUpperCase() ?? '';
    },
    type(): string {
      return this.isShowReferredBlock ? this.referrerType : '';
    },
    btnUrl(): string {
      const email = encodeURIComponent(this.userEmail.trim());
      return email.length > 0
        ? `${SUBSCRIPTION_PAGE_LINK}?email=${email}`
        : SUBSCRIPTION_PAGE_LINK;
    },
    referrerText(): string {
      let text = '';
      switch (this.type) {
        case 'yandexNews':
          text = `Читайте ${this.siteName} на <a href=${this.referrerLink}>Яндекс.Новостях</a>`;
          break;
        case 'googleNews':
          text = `Читайте ${this.siteName} на <a href=${this.referrerLink}>Google News</a>`;
          break;
        case 'dzen':
          text = `Подпишись на наш канал в <a href=${this.referrerLink}>Дзен</a>`;
          break;
        default:
          break;
      }
      return text;
    },
    icon() {
      if (this.type === 'yandexNews') {
        return (
          <svg slot="icon" width={ICON_SIZE} height={ICON_SIZE}>
            <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-yandex-news"></use>
          </svg>
        );
      }

      if (this.type === 'dzen') {
        return (
          <svg slot="icon" width={ICON_SIZE} height={ICON_SIZE}>
            <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-yandex-zen"></use>
          </svg>
        );
      }

      if (this.type === 'googleNews') {
        return (
          <img
            slot="icon"
            src={GoogleNewsIcon}
            alt=""
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        );
      }
    }
  },
  mounted() {
    this.checkForReferrer();

    if (this.isShowReferredBlock) {
      this.subscriptionObsVisibility.event.subscribe(state => {
        if (state.value) {
          this.sendNewReachGoal('Просмотр', 'viewSubscribeBlock');
        }
      });
    }
  },
  methods: {
    clickSubscriptionsButton() {
      this.sendNewReachGoal('Клик', 'clickSubscribeBlock');
    },
    sendNewReachGoal(value: string, goalName: string) {
      const blockType = 'Центральная колонка';
      const fieldType = 'Блок подписки';
      const name = String(RG_NAMES_MAP[this.type]) || 'Email рассылка';
      const valueName = `${value} ${name}`;

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName
      });
    },
    checkForReferrer() {
      const { referrer } = document;
      const { href } = location;
      if (new RegExp(GOOGLE_NEWS_MATCH_STRING).exec(referrer) !== null) {
        this.referrerType = GN_BLOCK_TYPE;
      }
      if (new RegExp(DZEN_MATCH_STRING).exec(referrer) !== null) {
        this.referrerType = DZEN_BLOCK_TYPE;
      }
      if (
        new RegExp(YANDEX_NEWS_MATCH_STRING).exec(referrer) !== null ||
        new RegExp(YANDEX_NEWS_UTM_MATCH_STRING).exec(href) !== null
      ) {
        this.referrerType = YN_BLOCK_TYPE;
      }
      const isAllowedRegion = REFERRER_BLOCK_ALLOWED_REGIONS.includes(this.regionId);
      this.isShowReferredBlock = this.isE1 && this.referrerType !== '' && isAllowedRegion;
    }
  },
  render() {
    if (this.isShowReferredBlock) {
      return (
        <div
          v-observe-visibility={this.subscriptionObsVisibility.getOptions('half')}
          class={[styles.subscriptions, styles.referrerBlock]}
        >
          <JtnUiPromoBlock
            btnText={this.referrerLinkText}
            btnUrl={this.referrerLink}
            onBtnClicked={() => this.clickSubscriptionsButton()}
          >
            {this.icon}
            <p domPropsInnerHTML={this.referrerText} />
          </JtnUiPromoBlock>
        </div>
      );
    }

    return <div></div>;
  }
});
