import { componentFactoryOf } from 'vue-tsx-support';

import {
  tsStoreMixin,
  injectStylesMixin,
  AutoBlockMixin,
  BaseBlockFunctionalityMixin
} from '../../mixins';
import { Slider } from '../slider';

import styles from './news-slider.styles.scss?module';

interface Events {
  onSwipe: void;
  onLinkClicked: void;
}

type ComponentData = {
  sliderRendered: boolean;
  isBeginning: boolean;
  isEnd: boolean;
  currentGroup: number;
  slideWidth: number;
};

const SLIDE_GAP = 30;
const SLIDES_IN_SCREEN = 2;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(AutoBlockMixin)
  .mixin(BaseBlockFunctionalityMixin)
  .create({
    name: 'NewsSlider',
    props: {
      lastSlideIndex: {
        type: Number,
        default: 0
      },
      autoSlideInViewport: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        sliderRendered: false,
        isBeginning: true,
        isEnd: false,
        currentGroup: 0,
        slideWidth: 0
      };
    },
    computed: {
      isShowFirstGroup(): boolean {
        return this.currentGroup === 0;
      },
      isShowLastGroup(): boolean {
        return this.currentGroup === this.lastSlideIndex - 1;
      }
    },
    mounted() {
      this.slideWidth = this.getSlideWidth();
    },
    methods: {
      sliderReady() {
        this.sliderRendered = true;
        this.updateSliderButtons();
      },
      getSlideWidth(): number {
        const containerWidth = (this.$refs?.sliderWrapper as HTMLElement).offsetWidth;

        return Math.floor((containerWidth - SLIDE_GAP) / SLIDES_IN_SCREEN);
      },
      setGroup(groupIndex: number) {
        this.currentGroup = groupIndex;
        this.updateSliderButtons(groupIndex);
        this.$emit('swipe');
      },
      updateSliderButtons(groupIndex = 0) {
        this.isBeginning = groupIndex === 0;
        this.isEnd = groupIndex === this.lastSlideIndex;
      }
    },
    render() {
      return (
        <div ref="sliderWrapper" class={styles.slideList}>
          <Slider
            currentGroup={this.currentGroup}
            onSlideChanged={this.setGroup}
            onReady={this.sliderReady}
            autoSlideInViewport={this.autoSlideInViewport}
          >
            <template slot="slides">{this.$slots.default}</template>
          </Slider>
        </div>
      );
    }
  });
