import { AnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig, IAnalyticsService } from '../analytics';

enum GoalName {
  View = 'ViewSmi2Block',
  Click = 'ClickSmi2Block',
}

export interface ISmi2BlockAnalyticsService extends IAnalyticsService {
  sendEventBlockClick(): void;
  sendEventBlockShown(): void;
}

export type Smi2BlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class Smi2BlockAnalyticsService
  extends AnalyticsService
  implements ISmi2BlockAnalyticsService
{
  private readonly _blockName = 'СМИ2 (блок)';

  constructor(config: Smi2BlockAnalyticsServiceConfig) {
    super(config);
  }
  sendEventBlockShown(): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        valueName: 'Просмотр',
      },
    });
  }

  sendEventBlockClick(): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        valueName: 'Клик',
      },
    });
  }
}
