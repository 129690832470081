<template>
  <div
      v-observe-visibility="obsVisibilityOptions"
      :class="[
      $style.autoBlock,
      componentVisible ? $style.visible : '',
      isInread && canRender ? $style.inread : ''
    ]"
  >
    <template v-if="canRender && params.title && canRenderOnThisDevice">
      <JtnUiBaseBlock
          v-observe-visibility="obsHalfOptions"
          :header="params.title"
          :url="setUtmData(params.link)"
          :has-border="!sliderMode"
          target-url="_blank"
          @headerClicked="goToLink('Переход на сайт')"
      >
        <AutoSlider
            v-if="sliderMode"
            :items="items"
            :is-laptop="isLaptop"
            :is-desktop="isDesktop"
            @linkClicked="goToLink('Переход на объявление')"
            @swipe="sendSwipeAnalytics"
        />
        <AutoList v-else :list="items" @linkClicked="goToLink('Переход на объявление')"/>
        <JtnUiBtn
            :class="$style.btn"
            :href="btnData.url"
            :target="btnData.target"
            @click="goToLink('Кнопка Купить автомобиль')"
        >
          {{ btnData.text }}
        </JtnUiBtn>
      </JtnUiBaseBlock>
    </template>
  </div>
</template>

<script>
import { JtnUiBaseBlock, JtnUiBtn } from '@jtnews/jtn-ui';
import { guardUnspecified } from '@portal/utils/util-guards';

import { StoreMixin } from '../../mixins';
import { BaseBlockFunctionalityMixin } from '../../mixins/base-block-functionality.mixin';

import { AutoList } from '../auto-list';
import { AutoSlider } from '../auto-slider';
import { AutoBlockMixin } from '../../mixins/auto-block.mixin';

export default {
  name: 'AutoBlock',
  components: {
    AutoList,
    AutoSlider,
    JtnUiBaseBlock,
    JtnUiBtn
  },
  mixins: [StoreMixin, BaseBlockFunctionalityMixin, AutoBlockMixin],
  props: {
    isShowButton: {
      type: Boolean,
      default: true
    },
    isInread: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'auto'
    },
    devices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      blockData: {
        params: {},
        data: [],
        settings: {
          type: 'A'
        },
        reach_goal: null
      },
      itemsLimit: 4
    };
  },
  computed: {
    items() {
      return this.sliderMode ? this.blockData.data : this.blockData.data.reverse();
    },
    btnData() {
      return {
        text: this.params.button_title,
        url: this.setUtmData(this.params.button_link),
        align: 'right',
        target: '_blank',
        type: 'button-blue'
      };
    },
    sliderMode() {
      return (
          this.position === 'window-block' ||
          this.isInread ||
          this.isDesktop ||
          this.isTablet ||
          (this.isLaptop &&
              (this.position !== 'right-column' ||
                  (this.position === 'right-column' && this.isBrandingPage && !this.isAdblock)))
      );
    },
    isAdblock() {
      return this.store.isAdblock;
    },
    isTablet() {
      return this.store.deviceInfo.isTablet;
    },
    isLaptop() {
      return (
          typeof window !== 'undefined' &&
          window.innerWidth >= 900 &&
          window.innerWidth < 1280
      );
    },
    canRenderOnThisDevice() {
      if (this.isLaptop) {
        return this.devices.includes('laptop');
      }
      if (this.isDesktop) {
        return this.devices.includes('desktop');
      }
      if (this.isTablet) {
        return this.devices.includes('tablet');
      }
      if (this.isMobile) {
        return this.devices.includes('mobile');
      }
      return true;
    }
  },
  methods: {
    onVisibleBlock() {
      this.sendNewReachGoal('Просмотр', 'view');
      this.sendNewFormatReachGoal('view', 'Просмотр');
    },
    goToLink(valueName) {
      this.sendNewReachGoal(valueName, 'click');
      this.sendNewFormatReachGoal('Click', 'Клик');
    },
    sendSwipeAnalytics(swipeDirection) {
      this.sendNewReachGoal('Скроллинг объявлений', 'click', 'Scroll');
      this.sendNewFormatReachGoal('Scroll', 'Скролл', swipeDirection);
    },
    async componentShown() {
      try {
        const res = await this.newsApiClient.getAutoBlock({
          regionId: this.regionId,
          isAvito: this.type === 'Avito'
        });
        this.blockData = res.data;
        this.sendReachGoalFromApi(this.$_BaseBlockFuncMixin_generalReachGoals);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    sendNewFormatReachGoal(action, actionType, prop1) {
      const reachGoalMap = {
        Avito: {
          goalName: `${action}Avito`,
          blockType: 'Блок Авито',
          actionType,
          prop1
        },
        Auto: {
          goalName: `${action}Autoru`,
          blockType: 'Блок Авто.ру',
          actionType,
          prop1
        }
      };
      const reachGoal = reachGoalMap[this.type];
      if (guardUnspecified(reachGoal)) {
        this.store.analyticsModule.sendNewFormatReachGoal(reachGoal);
      }
    },
    sendNewReachGoal(valueName, goalType, element = 'Block') {
      const blockType =
          this.position === 'right-column' ? 'Правая колонка' : 'Центральная колонка';
      const fieldType = this.type === 'Auto' ? 'Авто.ру (блок)' : 'Авито (блок)';
      const goalName = `${goalType}${this.type}Ru${element}`;

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        willDeprecate: true
      });
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.autoBlock {
  width: 100%;
  height: 300px;

  @include on-min-resize($tablet) {
    height: initial;
  }

  &.visible {
    height: auto;
  }

  &.inread {
    box-sizing: border-box;
    padding: 15px 0;

    background-color: #efefef;

    @include on-min-resize($tablet) {
      padding: 15px 25px;
    }
  }

  .btn {
    margin-top: 20px;
  }
}
</style>
