







import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

import type { ISmi2BlockUseCase } from '@jtnews/shared/seedwork/frontend/application';
import { SMI2_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import { UiObserveVisibility } from '@jtnews/shared/ui';

type DeviceType = 'desktop' | 'mobile' | 'tablet';

@Component({
  name: 'SmiBlock',
  components: {
    UiObserveVisibility,
  },
})
export default class Smi2Block extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  partnerId: string;

  @Prop({
    type: Array,
    default: () => [],
  })
  devices: DeviceType[];

  @Inject(SMI2_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: ISmi2BlockUseCase;

  get blockId(): string {
    return `unit_${this.partnerId}`;
  }

  get scriptId(): string {
    return `script_smi2_${this.partnerId}`;
  }

  processViewAdd() {
    this._useCase.processViewAdd();
  }

  processClickAdd() {
    this._useCase.processClickAdd();
  }

  mounted() {
    this._useCase.inject(this.devices, this.scriptId, this.partnerId);
  }
}
