export const enum AnalyticsBlockPlace {
  Central = 'Центральная колонка',
  Right = 'Правая колонка',
  Left = 'Левая колонка',
  Forms = 'Формы',
  Footer = 'Футер',
  Menu = 'Меню',
  Content = 'Контент',
  Page = 'Вся страница',
}

export type BlockPlaceRepositoryConfig = {
  defaultPlace: AnalyticsBlockPlace;
  ignoreBreakpoints?: boolean;
};

export interface IBlockPlaceRepository {
  get(blockPlaceConfig: BlockPlaceRepositoryConfig): AnalyticsBlockPlace;
}
