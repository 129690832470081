import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiAvatar, JtnUiImage } from '@jtnews/jtn-ui';

import { injectStylesMixin } from '../../../mixins';

import styles from './love-block-item.styles.scss?module';

type ImageSource = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
};

type Events = {
  onCardClicked: Event;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'LoveBlockItem',
    props: {
      avatarData: {
        type: Object as () => { src: string; sources: ImageSource[] },
        default: {
          src: '',
          sources: []
        }
      },
      link: {
        type: String,
        default: ''
      },
      nick: {
        type: String,
        default: ''
      }
    },
    render() {
      return (
        <a
          class={styles.item}
          href={this.link}
          target="_blank"
          onClick={(event: Event) => this.$emit('cardClicked', event)}
        >
          <JtnUiAvatar class={styles.avatar} size={0}>
            {guardEmptyString(this.avatarData.src) && (
              <JtnUiImage
                slot="photo"
                src={this.avatarData.src}
                sources={this.avatarData.sources}
                width={110}
                height={110}
                decoding="async"
                alt="Фото пользователя"
              />
            )}
          </JtnUiAvatar>
          <p class={styles.nick}>{this.nick}</p>
        </a>
      );
    }
  });
