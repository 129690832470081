import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { getDomainByRegionId } from '@jtnews/shared/seedwork/bff/domain';
import { addQueryString } from '@smh/utils/url';
import { componentFactoryOf } from 'vue-tsx-support';

import {
  AspectRatio,
  JtnUiBaseBlock,
  JtnUiImage,
  JtnUiTopRecordsItem
} from '@jtnews/jtn-ui';
import {
  injectStylesMixin,
  tsStoreMixin,
  tsBaseBlockFunctionalityMixin,
  EntityDecoderMixin
} from '@jtnews/shared';
import { getResponsiveImageData } from '@jtnews/shared/images';
import { addUrlParams } from '@jtnews/shared/news';

import styles from './recommendations-block.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
    m => m.BlockedUserModal
  );

interface IEvents {
  onClickToHeader: Event;
}

type Image = {
  sources: {
    srcset: string;
    type?: string;
    media?: string;
  }[];
  hasCommercialLabel: boolean;
  src: string;
};

type Item = {
  id: number;
  header: string;
  commentsCount: number;
  isCommentsAllowed: boolean;
  viewsCount: number;
  isMain: boolean;
  photoData:
    | (Image & {
        loading: 'lazy' | 'eager';
        decoding: 'async' | 'sync' | 'auto';
        alt: string;
      })
    | null;
  urls: {
    url: string;
    urlComments: string;
  };
};

type ItemDTO = {
  id: number;
  header: string;
  commentsCount: number;
  isCommentsAllowed: boolean;
  viewsCount: number;
  mainPhoto: {
    width: number;
    url: string;
    description: string;
    hasCommercialLabel: boolean;
  };
  urls: {
    url: string;
    urlComments: string;
  };
};

type ComponentData = {
  blockData: {
    data: ItemDTO[];
    params?: {
      executor: string;
      title: string;
    };
  };
  isBlockedUserModalOpened: boolean;
  isBlockedUserModalRendered: boolean;
};

type NewFormatReachGoal = {
  goalName: string;
  actionType: string;
  prop1?: string;
  prop2?: string;
};

export default componentFactoryOf<IEvents>()
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .mixin(EntityDecoderMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'RecommendationsBlock',
    data(): ComponentData {
      return {
        blockData: {
          data: []
        },
        isBlockedUserModalOpened: false,
        isBlockedUserModalRendered: false
      };
    },
    computed: {
      items(): Item[] | null {
        return guardUnspecified(this.blockData.data)
          ? this.blockData.data.map((item, index) => {
              const isMain = index === 0;
              const mainPhotoData =
                isMain && guardEmptyString(item.mainPhoto.url || '')
                  ? getResponsiveImageData({
                      url: item.mainPhoto.url ?? '',
                      width: item.mainPhoto.width ?? 0,
                      hasCommercialLabel: item.mainPhoto.hasCommercialLabel,
                      values: [
                        {
                          breakpoint: 375,
                          width: 309
                        },
                        {
                          breakpoint: 599,
                          width: 533
                        },
                        {
                          breakpoint: 899,
                          width: 365
                        },
                        {
                          breakpoint: 1279,
                          width: 424
                        },
                        {
                          breakpoint: 0,
                          width: 266,
                          noMedia: true
                        }
                      ]
                    })
                  : null;
              return {
                ...item,
                isMain: index === 0,
                photoData: guardUnspecified(mainPhotoData)
                  ? {
                      src: mainPhotoData.data.src,
                      sources: mainPhotoData.data.sources,
                      hasCommercialLabel: mainPhotoData.data.hasCommercialLabel,
                      loading: mainPhotoData.data.loading || 'lazy',
                      decoding: mainPhotoData.data.decoding || 'auto',
                      alt: item.mainPhoto.description
                    }
                  : null,
                urls: {
                  url: this.addUTM(item.urls.url),
                  urlComments: this.addUTM(item.urls.urlComments)
                }
              };
            })
          : [];
      },
      newsApiClient(): unknown {
        return this.store.newsApiClient;
      },
      isBlockedUser(): boolean {
        return this.store.commonModule.isBlockedUser;
      },
      executor(): string {
        return this.blockData.params.executor || 'NA';
      },
      siteName(): string {
        return getDomainByRegionId(this.store.regionId);
      }
    },
    methods: {
      async componentShown() {
        try {
          const res = await this.newsApiClient.getRecommendationsBlock({
            regionId: this.store.regionId
          });
          this.blockData = res.data;

          this.$_BaseBlockFuncMixin_sendReachGoalFromApi(
            this.$_BaseBlockFuncMixin_generalReachGoals
          );
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      onVisibleBlock() {
        const ids = this.items?.map(item => item.id).join(', ') ?? '';
        this.sendNewReachGoal(
          { Просмотр: { [ids]: this.executor } },
          'viewWeRecommendBlock'
        );
        this.sendNewFormatReachGoal({
          actionType: 'Просмотр',
          goalName: 'ViewRecommendation',
          prop1: ids
        });
      },
      clickedDiscussLink(
        event: Event,
        value: string,
        id: number,
        executor: string,
        place: string
      ) {
        if (this.isBlockedUser) {
          event.preventDefault();
          this.openBlockedUserModal();
        } else {
          this.clickedLink(value, id, executor, place);
        }
      },
      clickedLink(value: string, id: number, executor: string, place?: string) {
        this.sendNewReachGoal({ [value]: { [id]: executor } }, 'clickWeRecommendBlock');
        this.sendNewFormatReachGoal({
          actionType: 'Клик',
          goalName: 'ClickRecommendation',
          prop1: id.toString(),
          prop2: place
        });
      },
      sendNewReachGoal(
        valueName: Record<string, Record<string, string>>,
        goalName: string
      ) {
        const desktopBreakpoint = 1279;
        const blockType =
          window.innerWidth > desktopBreakpoint
            ? 'Правая колонка'
            : 'Центральная колонка';
        const fieldType = 'Рекомендуем (блок)';

        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      sendNewFormatReachGoal({ actionType, goalName, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Рекомендуем',
          actionType,
          goalName,
          prop1,
          prop2
        });
      },
      getDiscussUrl(url: string): string {
        return addUrlParams(url, { discuss: '1' });
      },
      openBlockedUserModal() {
        this.isBlockedUserModalOpened = true;
        this.isBlockedUserModalRendered = true;
      },
      addUTM(url: string): string {
        const params = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          utm_source: 'sgp_recomended',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          utm_medium: 'rec',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          utm_campaign: this.siteName
        };
        return addQueryString(url, params);
      }
    },
    render() {
      return (
        <noindex>
          <div
            v-observe-visibility={this.obsVisibilityOptions}
            class={styles.recommendationsBlock}
          >
            {this.$_BaseBlockFuncMixin_canRender && (
              <JtnUiBaseBlock
                v-observe-visibility={this.obsHalfOptions}
                header={this.blockData.params.title}
                dataTestHeader="recommend_block"
              >
                <div class={styles.content}>
                  {this.items?.map((item: Item, index: number) => (
                    <JtnUiTopRecordsItem
                      class={index === 0 ? styles.primaryRecord : styles.secondaryRecord}
                      commentsCount={item.commentsCount}
                      commentsUrl={item.urls.urlComments}
                      discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                      header={this.entityDecode(item.header)}
                      isCommentsAllowed={item.isCommentsAllowed}
                      key={item.id}
                      url={item.urls.url}
                      viewsCount={item.viewsCount}
                      onRecordClicked={() =>
                        this.clickedLink(
                          'Переход на материал',
                          item.id,
                          this.executor,
                          'Материал'
                        )
                      }
                      onCommentsClicked={() =>
                        this.clickedLink(
                          'Переход на комментарии',
                          item.id,
                          this.executor,
                          'Комментарий'
                        )
                      }
                      onDiscussClicked={(event: Event) =>
                        this.clickedDiscussLink(
                          event,
                          'Переход на комментарии',
                          item.id,
                          this.executor,
                          'Комментарий'
                        )
                      }
                    >
                      {item.isMain && guardUnspecified(item.photoData) && (
                        <a
                          slot="image"
                          href={item.urls.url}
                          target="_blank"
                          onClick={() =>
                            this.clickedLink(
                              'Переход на материал',
                              item.id,
                              this.executor,
                              'Материал'
                            )
                          }
                        >
                          <JtnUiImage
                            class={styles.recordImageWrap}
                            src={item.photoData.src}
                            sources={item.photoData.sources}
                            aspectRatio={AspectRatio.Wide}
                            hasCommercialLabel={item.photoData.hasCommercialLabel}
                            decoding="async"
                            alt={item.photoData.alt}
                          />
                        </a>
                      )}
                    </JtnUiTopRecordsItem>
                  ))}
                </div>
              </JtnUiBaseBlock>
            )}
          </div>
          {this.isBlockedUserModalRendered && (
            <BlockedUserModal vModel={this.isBlockedUserModalOpened} />
          )}
        </noindex>
      );
    }
  });
