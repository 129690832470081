import {
  guardEmptyString,
  guardUnspecified,
  guardZeroNumber
} from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiBaseBlock, JtnUiNewsSliderItem, JtnUiOpinionsItem } from '@jtnews/jtn-ui';
import { getResponsiveImageData, ImageAspectRatio } from '@jtnews/shared/images';
import { addUrlParams } from '@jtnews/shared/news';

import type NewsClient from '../../../core/http/news-client.api';
import {
  EntityDecoderMixin,
  injectStylesMixin,
  tsBaseBlockFunctionalityMixin,
  tsStoreMixin
} from '../../mixins';
import { NewsSlider } from '../news-slider';

import styles from './opinions-block.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
    m => m.BlockedUserModal
  );

type NewFormatReachGoal = {
  goalName: string;
  actionType: string;
  prop1?: string;
  prop2?: string;
};

type ImageSource = {
  srcset: string;
  type?: string;
};

type Author = {
  id: number;
  image?: {
    fileName: string;
    height: number;
    width: number;
  };
  jobPosition: string;
  name: string;
  url: string;
};

type AuthorImage = {
  src: string;
  size: number;
  sources: ImageSource[];
};

type PreparedAuthor = Omit<Author, 'image'> & {
  image: AuthorImage | null;
};

type Item = {
  id: number;
  header: string;
  authors: Author[] | undefined;
  commentsCount: number;
  isCommentsAllowed: boolean;
  urls: {
    url: string;
    urlCanonical: string;
    urlComments: string;
  };
  viewsCount: number;
};

type ComponentData = {
  sliderHeight: number;
  slideHeight: string;
  isBlockedUserModalOpened: boolean;
  isBlockedUserModalRendered: boolean;
};

const AVATAR_SIZE = 48;
const SLIDER_INDENT = 26;

export default componentFactory
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .mixin(EntityDecoderMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'OpinionsBlock',
    data(): ComponentData {
      return {
        sliderHeight: 0,
        slideHeight: 'auto',
        isBlockedUserModalOpened: false,
        isBlockedUserModalRendered: false
      };
    },
    computed: {
      newsApiClient(): NewsClient {
        return this.store.newsApiClient;
      },
      isMobile() {
        return this.store.deviceInfo.isMobile;
      },
      sliderGutter(): number {
        return this.isMobile ? 12 : 30;
      },
      items(): Item[] {
        return (this.blockData?.data as Item[] | null) || [];
      },
      isBlockedUser() {
        return this.store.commonModule.isBlockedUser;
      },
      topLinks(): {
        title: string;
        url: string;
      }[] {
        return [
          {
            title: 'Мнение',
            url: this.$_BaseBlockFuncMixin_params.link as string
          }
        ];
      }
    },
    methods: {
      getSliderHeight(): void {
        let maxHeight = 0;

        const slides: Vue[] = this.items.map((item, index) => {
          return this.$refs[`slide-${index}`] as Vue;
        });

        slides.forEach(item => {
          const { height } = item.$el.getBoundingClientRect();

          if (height > maxHeight) {
            maxHeight = height;
          }
        });

        this.sliderHeight = maxHeight;
        this.slideHeight = `${this.sliderHeight - SLIDER_INDENT}px`;
      },
      async componentShown(): Promise<void> {
        try {
          const res = await this.newsApiClient.getOpinionsBlock({
            regionId: this.$_BaseBlockFuncMixin_regionId
          });

          this.blockData = res.data;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      sendNewFormatReachGoal({ actionType, goalName, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'ТОП 5',
          actionType,
          goalName,
          prop1,
          prop2
        });
      },
      onVisibleBlock() {
        if (this.isMobile) {
          this.getSliderHeight();
        }

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Мнения (блок)',
          'Просмотр',
          'viewOpinionsBlock',
          true
        );
        this.sendNewFormatReachGoal({
          actionType: 'Просмотр',
          goalName: 'ViewOpinions'
        });
      },
      getAuthorImage(author: Author): AuthorImage | null {
        if (
          !guardUnspecified(author) ||
          !guardUnspecified(author.image) ||
          !guardEmptyString(author.image.fileName)
        ) {
          return null;
        }

        const avatarData = getResponsiveImageData({
          url: author.image.fileName,
          width: AVATAR_SIZE,
          aspectRatio: ImageAspectRatio.Square,
          values: [
            {
              width: AVATAR_SIZE,
              breakpoint: 0,
              noMedia: true
            }
          ]
        });

        return {
          src: avatarData.data.src,
          size: AVATAR_SIZE,
          sources: avatarData.data.sources
        };
      },
      getDiscussUrl(url: string): string {
        return addUrlParams(url, { discuss: '1' });
      },
      goToDiscussLink(
        event: Event,
        reachGoalValues: Object, //eslint-disable-line @typescript-eslint/ban-types
        placeName: string,
        id: number
      ) {
        if (this.isBlockedUser === true) {
          event.preventDefault();
          this.openBlockedUserModal();
        } else {
          this.clickLink(reachGoalValues, placeName, id);
        }
      },
      clickLink(
        reachGoalValues: Object | string, //eslint-disable-line @typescript-eslint/ban-types
        placeName: string,
        id: number | string
      ) {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Мнения (блок)',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          reachGoalValues,
          'clickOpinionsBlock',
          true
        );
        this.sendNewFormatReachGoal({
          actionType: 'Клик',
          goalName: 'ClickOpinions',
          prop1: `${id}`,
          prop2: placeName
        });
      },
      getAuthor(item: Item): PreparedAuthor | null {
        if (!guardUnspecified(item.authors) || !guardZeroNumber(item.authors.length)) {
          return null;
        }

        return {
          ...item.authors[0],
          image: this.getAuthorImage(item.authors[0])
        };
      },
      clickToHeader(): void {
        this.clickLink('Переход на список Мнений', 'Заголовок', 'Заголовок');
      },
      clickToAuthor(item: Item): void {
        const author = this.getAuthor(item);

        if (author === null) {
          return;
        }

        this.clickLink(
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Переход на профиль автора': {
              [author.id]: item.id
            }
          },
          'Автор',
          item.id
        );
      },
      clickToRecord(item: Item): void {
        const author = this.getAuthor(item);

        if (author === null) {
          return;
        }

        this.clickLink(
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Переход на материал': { [item.id]: author.id }
          },
          'Материал',
          item.id
        );
      },
      clickToComments(item: Item): void {
        const author = this.getAuthor(item);

        if (author === null) {
          return;
        }

        this.clickLink(
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Переход на комментарии': { [item.id]: author.id }
          },
          'Комментарий',
          item.id
        );
      },
      clickToDiscuss(event: Event, item: Item): void {
        const author = this.getAuthor(item);

        if (author === null) {
          return;
        }

        this.goToDiscussLink(
          event,
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Переход на комментарии': { [item.id]: author.id }
          },
          'Комментарий',
          item.id
        );
      },
      openBlockedUserModal() {
        this.isBlockedUserModalOpened = true;
        this.isBlockedUserModalRendered = true;
      }
    },
    render() {
      return (
        <noindex>
          <div
            v-observe-visibility={this.obsVisibilityOptions}
            class={styles.opinionsBlock}
          >
            {guardZeroNumber(this.items.length) &&
              (this.isMobile ? (
                <NewsSlider
                  v-observe-visibility={this.obsHalfOptions}
                  lastSlideIndex={this.items.length}
                  class={styles.slider}
                  style={{ height: `${this.sliderHeight}px` }}
                  autoSlideInViewport={true}
                >
                  {this.items.map((item, index) => (
                    <JtnUiNewsSliderItem
                      key={item.id}
                      ref={`slide-${index}`}
                      class={styles.sliderItem}
                      style={{
                        height: this.slideHeight
                      }}
                      header={this.entityDecode(item.header)}
                      topLinks={this.topLinks}
                      author={this.getAuthor(item)}
                      isCommentsAllowed={item.isCommentsAllowed}
                      url={item.urls.url}
                      viewsCount={item.viewsCount}
                      commentsCount={item.commentsCount}
                      commentsUrl={item.urls.urlComments}
                      discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                      onTopLinkClicked={() => this.clickToHeader()}
                      onAuthorClicked={() => this.clickToAuthor(item)}
                      onRecordClicked={() => this.clickToRecord(item)}
                      onCommentsClicked={() => this.clickToComments(item)}
                      onDiscussClicked={(event: Event) => {
                        this.clickToDiscuss(event, item);
                      }}
                    />
                  ))}
                </NewsSlider>
              ) : (
                <JtnUiBaseBlock
                  v-observe-visibility={this.obsHalfOptions}
                  header={this.$_BaseBlockFuncMixin_params.title as string}
                  url={this.$_BaseBlockFuncMixin_params.link as string}
                  dataTestHeader="opinion_block"
                  onHeaderClicked={() => this.clickToHeader()}
                >
                  <div class={styles.list}>
                    {this.items.map(item => (
                      <JtnUiOpinionsItem
                        class={styles.item}
                        key={item.id}
                        header={this.entityDecode(item.header)}
                        author={this.getAuthor(item)}
                        gutter={this.sliderGutter}
                        isCommentsAllowed={item.isCommentsAllowed}
                        url={item.urls.url}
                        viewsCount={item.viewsCount}
                        commentsCount={item.commentsCount}
                        commentsUrl={item.urls.urlComments}
                        discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                        onAuthorClicked={() => this.clickToAuthor(item)}
                        onRecordClicked={() => this.clickToRecord(item)}
                        onCommentsClicked={() => this.clickToComments(item)}
                        onDiscussClicked={(event: Event) => {
                          this.clickToDiscuss(event, item);
                        }}
                      />
                    ))}
                  </div>
                </JtnUiBaseBlock>
              ))}
          </div>
          {this.isBlockedUserModalRendered && (
            <BlockedUserModal vModel={this.isBlockedUserModalOpened} />
          )}
        </noindex>
      );
    }
  });
