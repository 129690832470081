<template>
  <div :class="layoutClasses">
    <jtn-ui-loader-line
      v-if="!isNewRecordDesign && !isShowNewsSlider && !isVideoPage"
    />

    <slot name="layout-header" />

    <div ref="innerColumnsWrapper" class="inner-columns-wrapper">
      <div v-if="renderLeftColumn && !isMobileOnly" class="left-column-container">
        <template v-if="$slots['left-column']">
          <slot name="left-column" />
        </template>
      </div>

      <div :class="['central-right-wrapper', isMobileOnly ? '_mobile' : '']">
        <div class="central-column-container">
          <template v-if="$slots['central-column']">
            <slot name="central-column" />
          </template>
        </div>

        <div v-if="renderRightColumn" class="right-column-container">
          <template v-if="$slots['right-column']">
            <slot name="right-column" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JtnUiLoaderLine } from '@jtnews/jtn-ui';
import { PagesType } from '@jtnews/shared/data';
import { guardUnspecified } from '@smh/utils/guards';
import { StoreMixin } from '../../../shared/mixins';
import { ScrollBlockAnalytics } from '../../../shared/mixins/scroll-block-analycs.mixin';

export default {
  name: 'ColumnsWrapper',
  components: {
    JtnUiLoaderLine
  },
  mixins: [StoreMixin, ScrollBlockAnalytics],
  props: {
    hasSuperMain: {
      type: Boolean,
      default: false
    },
    renderLeftColumn: {
      type: Boolean,
      default: true
    },
    renderRightColumn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrollTargets: [
        { value: 25, goalName: 'viewScroll25Main' },
        { value: 50, goalName: 'viewScroll50Main' },
        { value: 75, goalName: 'viewScroll75Main' },
        { value: 100, goalName: 'viewScroll100Main' }
      ],
      sendScrollTargets: []
    };
  },
  computed: {
    isDesktop() {
      return this.store.deviceInfo.isDesktop;
    },
    isMobileOnly() {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    },
    pageType() {
      return this.store.pageType;
    },
    isBrandingPage() {
      const { aTemplateBlock } = this.store.advModule;

      return (
        this.isDesktop &&
        !this.isAdblock &&
        guardUnspecified(aTemplateBlock?.data?.adfox_branding)
      );
    },
    hasSupercover() {
      return !!this.store.layoutModule?.supercover;
    },
    mainPageTemplateLabel() {
      if (this.hasSupercover) {
        return 'Суперобложка';
      }
      return 'Стандартная';
    },
    isAdblock() {
      return this.store.isAdblock;
    },
    isNewRecordDesign() {
      return this.store.layoutModule.isNewRecordDesign;
    },
    layoutClasses() {
      return {
        'columns-wrapper': true,
        _branding: this.isBrandingPage,
        _record: this.pageType === PagesType.Record,
        _comments:
          this.pageType === PagesType.Comments || this.pageType === PagesType.Comment,
        _profile: this.pageType === PagesType.Profile,
        '_no-left-col': this.renderLeftColumn === false,
        '_no-right-col': this.renderRightColumn === false
      };
    },
    isShowNewsSlider() {
      return this.isMobileOnly && this.store.commonModule.sliderAbcTest !== 'A';
    },
    isVideoPage() {
      return this.store.pageType === PagesType.VideosOfTheDay;
    },
  },
  mounted() {
    if (this.pageType === PagesType.Main) {
      this.sendReachGoal('Скроллинг', 'Открыл', 'viewOpenMain');
      this.checkScrollElement = this.$refs.innerColumnsWrapper;
      window.addEventListener('scroll', this.onScroll);
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, false);
  },
  methods: {
    onScroll() {
      const element = this.$refs.innerColumnsWrapper;
      this.checkScrollTargets();
    },
    sendReachGoal(fieldType, value, goalName) {
      const blockType = 'Вся страница';
      const valueName = { [value]: this.mainPageTemplateLabel };
      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@use '~jtn-ui/z-index';

@import 'styles';

$left-column-notebook-width: 264px;
$super-block-height: 550px;

.columns-wrapper {
  padding: 0;

  background-color: $color-surface-200;

  &._super {
    .super-main-block {
      width: 100%;
    }

    @include on-min-resize($desktop) {
      .super-main-block {
        width: calc(100% - #{$right-column-width});
      }

      .right-column-container {
        margin-top: -$super-block-height;
      }
    }
  }

  &._profile {
    .central-column-container {
      max-width: 960px;
    }
  }

  &._branding {
    &._super {
      & .super-main-block {
        width: 100%;
      }

      & .right-column-container {
        margin-top: 0;
      }
    }

    @include on-min-resize($desktop) {
      padding: $desk-side-indent $desktop-padding;
    }
  }

  @include on-res($desktop-tablet) {
    padding: $desk-side-indent $desktop-padding;
  }
}

.under-adv {
  margin-bottom: 8px;
  padding-top: 0;
}

.inner-columns-wrapper {
  display: flex;

  box-sizing: border-box;

  background-color: $color-surface;

  ._branding & {
    .left-column-container {
      width: $left-column-notebook-width;
      min-width: $left-column-notebook-width;
    }
  }

  &:not(._branding &) {
    @include on-res($desktop) {
      .left-column-container {
        width: $left-column-notebook-width;
        min-width: $left-column-notebook-width;
      }
    }
  }

  @include on-res($tablet) {
    .left-column-container {
      display: none;
    }
  }
}

.central-right-wrapper {
  display: flex;
  flex-grow: 1;

  width: calc(100% - #{$left-column-width});

  &:not(._branding &) {
    @include on-res($desktop) {
      flex-direction: column;

      width: calc(100% - (#{$left-column-width} + #{$mobile-padding}));
    }
  }

  ._branding & {
    flex-direction: column;

    width: calc(100% - (#{$left-column-width} + #{$mobile-padding}));
  }

  &._mobile {
    flex-direction: column;

    width: calc(100% - (#{$left-column-width} + #{$mobile-padding}));
  }
}

.left-column-container {
  box-sizing: border-box;
  width: $left-column-width;
  min-width: $left-column-width;
}

.central-column-container {
  box-sizing: border-box;

  width: 100%;

  padding: 0 $central-column-mobile-padding;

  ._no-right-col & {
    width: 100%;
    border-right: none;
  }

  ._branding & {
    flex-grow: 0;

    width: 100%;
    border: none;
  }

  @include on-min-resize($tablet) {
    padding: 16px $central-column-desktop-padding 0;
  }

  @include on-min-resize($desktop) {
    width: calc(100% - #{$right-column-width});
  }
}

.right-column-container {
  position: relative;
  z-index: z-index.$base;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;

  padding: 0 $central-column-mobile-padding;

  background-color: $color-surface;

  .right-column {
    position: relative;
  }

  @include on-min-resize($tablet) {
    padding: 0 $central-column-desktop-padding;
  }

  @include on-min-resize($desktop) {
    width: $right-column-width;
    padding: 0;

    ._branding & {
      width: 100%;
      padding: 0 $central-column-desktop-padding;
    }
  }
}
</style>
