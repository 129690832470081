import { AnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig, IAnalyticsService } from '../analytics';

enum GoalName {
  View = 'ViewSparrowBlock',
  Click = 'ClickSparrowBlock',
}

export interface ISparrowBlockAnalyticsService extends IAnalyticsService {
  sendEventBlockClick(): void;
  sendEventBlockShown(): void;
}

export type SparrowBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class SparrowBlockAnalyticsService
  extends AnalyticsService
  implements ISparrowBlockAnalyticsService
{
  private readonly _blockName = 'Sparrow (блок)';

  constructor(config: SparrowBlockAnalyticsServiceConfig) {
    super(config);
  }
  sendEventBlockShown(): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        valueName: 'Просмотр',
      },
    });
  }

  sendEventBlockClick(): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        valueName: 'Клик',
      },
    });
  }
}
