/* eslint-disable */
// TODO: отрефакторить и пофиксить типы

/**
 * @deprecated заменить на https://github.com/developit/mitt
 */
import { Subject } from 'rxjs';

import { OBSERVER_VISIBILITY_CONFIG } from './config';

export type ObsVisibilityHook = 'lazyLoad' | 'half' | 'all' | 'custom' | 'most';

export type ObsVisibilityOptions = {
  [hook in ObsVisibilityHook]?: {
    intersection?: {
      root?: HTMLElement | null;
      rootMargin?: string;
      threshold?: number;
      throttle?: number;
    };
    once?: boolean;
    throttle?: number;
    throttleOptions?: {
      leading: string;
    };
    callback?: (value: boolean, entry: IntersectionObserverEntry) => void;
  };
};

export type ObsVisibilityEvent = {
  hook: ObsVisibilityHook | string;
  value: boolean;
  target: Element;
  isIntersecting: boolean;
};

export class ObserverVisibility {
  private readonly _hooks: ObsVisibilityHook | ObsVisibilityHook[];
  private readonly _customOptions: ObsVisibilityOptions | undefined;
  private readonly _event$: Subject<ObsVisibilityEvent>;
  private readonly _options: ObsVisibilityOptions = {};

  get event(): Subject<ObsVisibilityEvent> {
    return this._event$;
  }

  private get _normalizeHooks(): ObsVisibilityHook[] {
    return typeof this._hooks === 'string' ? [this._hooks] : [...this._hooks];
  }

  constructor(
    hooks: ObsVisibilityHook | ObsVisibilityHook[],
    customOption?: ObsVisibilityOptions
  ) {
    this._hooks = hooks;
    this._customOptions = customOption;
    this._event$ = new Subject<ObsVisibilityEvent>();
    this._configureOptions();
  }

  getOptions(hook: ObsVisibilityHook): ObsVisibilityOptions[keyof ObsVisibilityOptions] | void {
    try {
      this._checkHook(hook);

      return this._options[hook];
    } catch (e) {
      console.error(e);
    }
  }

  destroy(): void {
    this._event$.complete();
    this._event$.unsubscribe();
  }

  private _configureOptions(): void {
    this._normalizeHooks.forEach(hook => {
      const customOption = this._customOptions?.[hook] ?? {};
      this._options[hook] = Object.assign(
        {},
        OBSERVER_VISIBILITY_CONFIG[hook],
        customOption
      );

      this._options[hook] = {
        ...this._options[hook],
        callback: this._getCallback(hook, this.event)
      };
    });
  }

  private _getCallback(
    hook: ObsVisibilityHook | string,
    event$: Subject<ObsVisibilityEvent>
  ): (value: boolean, entry: IntersectionObserverEntry) => void {
    return function (value: boolean, entry: IntersectionObserverEntry) {
      event$.next({
        hook,
        value,
        target: entry.target,
        isIntersecting: entry.isIntersecting
      });
    };
  }

  private _checkHook(hook: ObsVisibilityHook): void {
    if (!this._options[hook]) {
      throw new Error(`Hook '${hook}' for observer visibility options is not defined`);
    }
  }
}
