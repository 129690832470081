import { Regions } from '@smh/projects/regions';

import { ActiveRegions } from '@jtnews/shared/data';

export type ReferrerTypes = 'yandexNews' | 'dzen' | 'googleNews';

export const YANDEX_NEWS_MATCH_STRING = 'yandex.ru/news';
export const YANDEX_NEWS_UTM_MATCH_STRING = 'utm_source=yxnews';
export const GOOGLE_NEWS_MATCH_STRING =
  'android-app://com.google.android.googlequicksearchbox/|android-app://com.google.android.googlequicksearchbox/https/www.google.com|news.google.com|googleapis.com|discover.google.com';
export const DZEN_MATCH_STRING = /dzen.ru/;

export const YANDEX_NEWS_MAP: Partial<Record<ActiveRegions, number>> = {
  [Regions.Yekaterinburg]: 2965,
  [Regions.Nsk]: 1310,
  [Regions.NizhnyNovgorod]: 2904,
  [Regions.Krasnoyarsk]: 6788,
  [Regions.Omsk]: 12354,
  [Regions.Chelyabinsk]: 2264,
  [Regions.Perm]: 3517,
  [Regions.Tyumen]: 6441,
  [Regions.Ufa]: 6676,
  [Regions.Volgograd]: 6687,
  [Regions.Rostov]: 6779,
  [Regions.Arkhangelsk]: 18190,
  [Regions.Yaroslavl]: 254054045,
  [Regions.Samara]: 6494,
  [Regions.Kurgan]: 254064779,
  [Regions.Kemerovo]: 14614,
  [Regions.KhantyMansiysk]: 7555,
  [Regions.KrasnodarRugion]: 17592
};

export const DZEN_MAP: Partial<Record<ActiveRegions, string>> = {
  [Regions.Yekaterinburg]: 'e1.ru',
  [Regions.Nsk]: 'ngs',
  [Regions.NizhnyNovgorod]: 'nn.ru',
  [Regions.Krasnoyarsk]: 'ngs24.ru',
  [Regions.Omsk]: 'ngs55.ru',
  [Regions.Chelyabinsk]: '74.ru',
  [Regions.Perm]: '59.ru',
  [Regions.Tyumen]: '72.ru',
  [Regions.Ufa]: 'ufa1.ru',
  [Regions.Volgograd]: 'v1.ru',
  [Regions.Rostov]: '161.ru',
  [Regions.Arkhangelsk]: '29.ru',
  [Regions.Yaroslavl]: '76.ru',
  [Regions.Samara]: '63.ru',
  [Regions.Kurgan]: '45.ru',
  [Regions.Kemerovo]: 'ngs42.ru'
};

export const GOOGLE_NEWS_MAP: Partial<Record<ActiveRegions, string>> = {
  [Regions.Yekaterinburg]: 'CAAqBwgKMKbKhgswwNiEAw',
  [Regions.Nsk]: 'CAAqBwgKML_Vhgswyc-EAw',
  [Regions.NizhnyNovgorod]: 'CAAqBwgKMKXjhgsw69mEAw',
  [Regions.Krasnoyarsk]: 'CAAqBwgKMNHLhgswv9aEAw',
  [Regions.Omsk]: 'CAAqBwgKMIrRhgswqdaEAw',
  [Regions.Chelyabinsk]: 'CAAqBwgKMJnVhgswurqEAw',
  [Regions.Perm]: 'CAAqBwgKMMDZhgswufSEAw',
  [Regions.Tyumen]: 'CAAqBwgKMIbThgsw0NaEAw',
  [Regions.Ufa]: 'CAAqBwgKMOvmhgswtu-EAw',
  [Regions.Volgograd]: 'CAAqBwgKMOzmhgswt--EAw',
  [Regions.Rostov]: 'CAAqBwgKMNnohgswte-EAw',
  [Regions.Arkhangelsk]: 'CAAqBwgKMLvmhgswnOyEAw',
  [Regions.Yaroslavl]: 'CAAqBwgKMLPlhgswkPCEAw',
  [Regions.Samara]: 'CAAqBwgKMJblhgsw4duEAw',
  [Regions.Kurgan]: 'CAAqBwgKMNDnhgswtvaEAw',
  [Regions.Kemerovo]: 'CAAqBwgKMOalmwswqrCzAw',
  [Regions.KhantyMansiysk]: 'CAAqBwgKMJLYogsw1eK6Aw'
};

export const RG_NAMES_MAP: { [key in ReferrerTypes]: string } = {
  yandexNews: 'Яндекс Новости',
  dzen: 'Дзен',
  googleNews: 'Google Discover'
};

export const REFERRER_BLOCK_ALLOWED_REGIONS = Object.keys(YANDEX_NEWS_MAP).map(regionId =>
  Number(regionId)
);

export const REFERRER_BASE_LINK: Record<ReferrerTypes, string> = {
  yandexNews: 'https://yandex.ru/news/?favid=',
  dzen: 'https://dzen.ru/',
  googleNews: 'https://news.google.com/publications/'
};
