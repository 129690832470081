import { injectScript } from '@smh/utils/dom';

import type { ISmi2BlockAnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';
import { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, BlockUseCaseConfig, IBlockUseCase } from '../blocks';

type DeviceType = 'desktop' | 'laptop' | 'tablet' | 'mobile';

export const SMI2_BLOCK_USE_CASE_KEY = 'smi2_block_use_case_key';

export interface ISmi2BlockStore {
  deviceInfo: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
}

export type Smi2BlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    store: ISmi2BlockStore;
    analyticsService: ISmi2BlockAnalyticsService;
  };
};

export interface ISmi2BlockUseCase extends IBlockUseCase {
  inject(devices: DeviceType[], scriptId: string, partnerId: string): void;
  getCanRenderOnThisDevice(devices: DeviceType[]): boolean;
  processViewAdd(): void;
  processClickAdd(): void;
}

export class Smi2BlockUseCase extends BlockUseCase implements ISmi2BlockUseCase {
  private readonly _store: ISmi2BlockStore;
  private readonly _analyticsService: ISmi2BlockAnalyticsService;
  constructor(config: Smi2BlockUseCaseConfig) {
    super(config);
    const {
      deps: { store, analyticsService },
    } = config;
    this._store = store;
    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processViewAdd() {
    this._analyticsService.sendEventBlockShown();
  }
  public processClickAdd() {
    this._analyticsService.sendEventBlockClick();
  }

  public inject(devices: DeviceType[], scriptId: string, partnerId: string): void {
    if (!this.getCanRenderOnThisDevice(devices)) {
      return;
    }
    const script = document.getElementById(scriptId);
    if (script) {
      script.remove();
    }

    void injectScript(
      {
        src: `//smi2.ru/data/js/${partnerId}.js`,
        id: scriptId,
      },
      true,
    );
  }

  public getCanRenderOnThisDevice(devices: DeviceType[]) {
    if (this._store.deviceInfo.isDesktop) {
      return devices.includes('desktop');
    }
    if (this._store.deviceInfo.isTablet) {
      return devices.includes('tablet');
    }
    if (this._store.deviceInfo.isMobile) {
      return devices.includes('mobile');
    }
    return true;
  }
}
