import { guardZeroNumber } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import {
  JtnUiBaseBlock,
  JtnUiBtn,
  JtnUiRadioBtn,
  JtnUiRadioGroup,
  JtnUiRangeSlider
} from '@jtnews/jtn-ui';
import { getResponsiveImageData, ImageAspectRatio } from '@jtnews/shared/images';

import {
  EntityDecoderMixin,
  injectStylesMixin,
  tsBaseBlockFunctionalityMixin,
  tsStoreMixin
} from '../../mixins';
import { NewsSlider } from '../news-slider';

import LoveBlockItem from './item/love-block-item.component';
import styles from './love-block.styles.scss?module';

type Target = {
  avatar: string;
  link: string;
  nick: string;
  age?: number;
};

type ComponentData = {
  sex: 1 | 0 | 10;
  hasSliderChanged: boolean;
  ages: [number, number];
};

const SEX_BTNS = [
  {
    id: 10,
    label: 'Друзей'
  },
  {
    id: 1,
    label: 'Парня'
  },
  {
    id: 0,
    label: 'Девушку'
  }
] as const;

export default componentFactory
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .mixin(EntityDecoderMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'LoveBlock',
    data(): ComponentData {
      return {
        sex: 10,
        hasSliderChanged: false,
        ages: [30, 35]
      };
    },
    computed: {
      newsApiClient() {
        return this.store.newsApiClient;
      },
      items(): Target[] {
        return (this.blockData.data as Target[] | null) || [];
      }
    },
    methods: {
      async componentShown() {
        try {
          const res = await this.newsApiClient.getLoveBlock({
            regionId: this.$_BaseBlockFuncMixin_regionId,
            pagesize: 5
          });
          this.blockData = res.data;
          this.sex = res.data.params.sex as 1 | 0 | 10;

          this.$_BaseBlockFuncMixin_sendReachGoalFromApi(
            this.$_BaseBlockFuncMixin_generalReachGoals
          );
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      onVisibleBlock() {
        this.sendOldFormatRG('Просмотр', 'viewLoveBlock');
        this.sendNewFormatReachGoal({ goalName: 'View' });
      },
      sendHeaderClickedRG() {
        this.sendOldFormatRG('Знакомства (заголовок)');
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: 'Заголовок'
        });
      },
      sendProfileClickedRG() {
        this.sendOldFormatRG('Переход на профиль');
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: 'Переход на профиль'
        });
      },
      sendTargetClickedRG(label: typeof SEX_BTNS[number]['label']) {
        this.sendOldFormatRG({ ['Я ищу']: label });
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: `Я ищу ${label}`
        });
      },
      sendFindClickedRG() {
        this.sendOldFormatRG('Найти');
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: 'Найти'
        });
      },
      sendOldFormatRG(
        value: string | Record<string, string>,
        goalName: 'clickLoveBlock' | 'viewLoveBlock' = 'clickLoveBlock'
      ) {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Знакомства (блок)',
          value,
          goalName,
          true
        );
      },
      sendNewFormatReachGoal({
        goalName,
        prop1,
        prop2
      }: {
        goalName: 'View' | 'Click';
        prop1?: string;
        prop2?: string;
      }) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Знакомства (блок)',
          actionType: goalName === 'View' ? 'Просмотр' : 'Клик',
          goalName: `${goalName}Love`,
          prop1,
          prop2
        });
      },
      onChangeSlider() {
        if (this.hasSliderChanged) {
          return;
        }

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Знакомства (блок)',
          'Возраст',
          'clickLoveBlock',
          true
        );
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: 'Возраст'
        });

        this.hasSliderChanged = true;
      },
      getButtonLink() {
        const s = 's=full';
        const fromAge = `from_age=${this.ages[0]}`;
        const toAge = `to_age=${this.ages[1]}`;
        const sex = `sex=${this.sex}`;
        const geoCity = `geo_city=${this.$_BaseBlockFuncMixin_params.geo_city}`;
        const geoRegion = `geo_region=${this.$_BaseBlockFuncMixin_params.geo_region}`;
        const geoName = `geo_name=${this.$_BaseBlockFuncMixin_params.geo_name}`;

        // eslint-disable-next-line
        return `${this.$_BaseBlockFuncMixin_params.button_link}/?${s}&${fromAge}&${toAge}&${sex}&${geoCity}&${geoRegion}&${geoName}`;
      },
      getUserName(item: Target): string {
        if (!item.age) {
          return item.nick;
        }

        return `${item.nick}, ${item.age}`;
      },
      getAvatarData(avatar: string) {
        const avatarData = getResponsiveImageData({
          url: avatar,
          width: 110,
          aspectRatio: ImageAspectRatio.Square,
          values: [
            {
              width: 110,
              breakpoint: 0,
              noMedia: true
            }
          ]
        });
        return {
          src: avatarData.data.src,
          sources: avatarData.data.sources
        };
      }
    },
    render() {
      return (
        <div v-observe-visibility={this.obsVisibilityOptions}>
          {guardZeroNumber(this.items?.length) && (
            <JtnUiBaseBlock
              v-observe-visibility={this.obsHalfOptions}
              header={this.$_BaseBlockFuncMixin_params.title as string}
              url={this.$_BaseBlockFuncMixin_params.link as string}
              onHeaderClicked={() => this.sendHeaderClickedRG()}
              class={styles.loveBlock}
              dataTestHeader={'love_block'}
            >
              <NewsSlider class={styles.slider} lastSlideIndex={this.items.length}>
                {this.items.map(item => (
                  <LoveBlockItem
                    key={item.nick}
                    class={styles.item}
                    avatarData={this.getAvatarData(item.avatar)}
                    nick={this.getUserName(item)}
                    link={item.link}
                    onCardClicked={() => this.sendProfileClickedRG()}
                  />
                ))}
              </NewsSlider>
              <JtnUiRadioGroup class={styles.groupe}>
                <template slot="caption">
                  <p class={styles.label}>Я ищу</p>
                </template>
                <template slot="items">
                  {SEX_BTNS.map(btn => (
                    <JtnUiRadioBtn
                      key={btn.id}
                      name={btn.id.toString()}
                      value={btn.label}
                      checked={this.sex === btn.id}
                      onChange={() => {
                        this.sex = btn.id;
                        this.sendTargetClickedRG(btn.label);
                      }}
                    >
                      {btn.label}
                    </JtnUiRadioBtn>
                  ))}
                </template>
              </JtnUiRadioGroup>
              <JtnUiRangeSlider
                v-model={this.ages}
                caption="Возраст"
                min={18}
                max={99}
                class={styles.range}
                onSliderChanged={() => this.onChangeSlider()}
              />
              <JtnUiBtn
                href={this.getButtonLink()}
                target="_blank"
                class={styles.button}
                onClick={() => this.sendFindClickedRG()}
              >
                <template slot="right">
                  <svg width="24" height="24">
                    <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-line-right"></use>
                  </svg>
                </template>
                Найти {SEX_BTNS.find(btn => btn.id === this.sex)?.label}
              </JtnUiBtn>
            </JtnUiBaseBlock>
          )}
        </div>
      );
    }
  });
