/* eslint-disable */
import { getPageYOffset, getElementOffset } from '@jtnews/shared/scroll-and-position';

export const ScrollBlockAnalytics = {
  methods: {
    checkScrollTargets() {
      const pageYOffset = getPageYOffset();
      const elTopCoords = getElementOffset(this.checkScrollElement).top;
      const elBottomCoords = getElementOffset(this.checkScrollElement).bottom;
      const windowHeight = window.innerHeight;

      const visibleContent = pageYOffset + windowHeight - elTopCoords;
      const contentHeight = elBottomCoords - elTopCoords;
      const percentContentViewing = Math.ceil((visibleContent * 100) / contentHeight);

      for (let index = 0; index < this.scrollTargets.length; index++) {
        if (
          this.sendScrollTargets.indexOf(this.scrollTargets[index].value) < 0 &&
          percentContentViewing >= this.scrollTargets[index].value
        ) {
          this.sendReachGoal(
            'Скроллинг',
            this.scrollTargets[index].value + '%',
            this.scrollTargets[index].goalName
          );
          this.sendScrollTargets.push(this.scrollTargets[index].value);
        }
      }
    }
  }
};
