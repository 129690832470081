/* eslint-disable */
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins';

import 'flickity/dist/flickity.min.css';
import styles from './slider.styles.scss?module';

interface Events {
  onSlideChanged: number;
  onReady: void;
  onSliderSwiped: TouchEvent;
  onSliderClicked: void;
  onSliderSettled: number;
}

type ComponentData = {
  slider: typeof import('flickity').prototype | null;
  intersectionObserver: IntersectionObserver | null;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'Slider',
    props: {
      isFullscreen: {
        type: Boolean,
        default: false
      },
      isDraggable: {
        type: Boolean,
        default: true
      },
      groupSize: {
        type: Number,
        default: 0
      },
      currentGroup: {
        type: Number,
        default: 0
      },
      hasThumbs: {
        type: Boolean,
        default: false
      },
      pageDots: {
        type: Boolean,
        default: false
      },
      needRenderSlider: {
        type: Boolean,
        default: false
      },
      isBlockedSlider: {
        type: Boolean,
        default: false
      },
      initialIndex: {
        type: Number,
        default: 0
      },
      needFocus: {
        type: Boolean,
        default: false
      },
      autoSlideInViewport: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        slider: null,
        intersectionObserver: null,
      };
    },
    watch: {
      currentGroup(val: number) {
        if (!this.slider) {
          return;
        }

        this.slider.select(val);
      },
      needRenderSlider(val: boolean) {
        if (!this.slider) {
          return;
        }

        if (val) {
          this.initSlider();
        } else {
          this.slider.destroy();
        }
      },
      isBlockedSlider(val: boolean) {
        this.processSliderSwipe(val);
      }
    },
    mounted() {
      this.initSlider();
    },
    beforeDestroy() {
      this.intersectionObserver?.unobserve(this.$refs.slider);
    },
    methods: {
      async initSlider(): Promise<void> {
        const { default: flickity } = await import('flickity');

        await import('flickity-as-nav-for');

        this.slider = new flickity(this.$refs['slider'], {
          fullscreen: this.isFullscreen,
          lazyLoad: true,
          draggable: this.isDraggable,
          pageDots: this.pageDots,
          prevNextButtons: false,
          groupCells: this.groupSize || undefined,
          setGallerySize: false,
          cellAlign: 'left',
          contain: true,
          initialIndex: this.initialIndex,
          on: {
            ready: () => {
              this.$emit('ready');
            },
            staticClick: () => {
              this.$emit('sliderClicked');
            },
            dragMove: (event: TouchEvent) => {
              this.$emit('sliderSwiped', event);
            },
            settle: (index: number) => {
              this.$emit('sliderSettled', index);
            }
          }
        });

        if (this.autoSlideInViewport) {
          this.intersectionObserver = new IntersectionObserver(
            entries => {
              if (entries.some(entry => entry.intersectionRatio === 1)) {
                this.slider?.next();
                this.intersectionObserver?.unobserve(this.$refs.slider);
              }
            },
            {
              threshold: 1,
              rootMargin: '0% 0% -30% 0%'
            }
          );

          this.intersectionObserver.observe(this.$refs.slider);
        }

        if (this.hasThumbs) {
          new flickity(this.$refs['thumbs'], {
            asNavFor: this.$refs['slider'],
            contain: true,
            pageDots: false,
            prevNextButtons: false
          });
        }

        this.slider.on('change', groupIndex => {
          this.$emit('slideChanged', groupIndex);
        });

        if (this.needFocus) {
          this.slider.focus();
        }
      },
      processSliderSwipe(isBlockedSlider: boolean): void {
        if (isBlockedSlider) {
          this.slider.options.draggable = false;
          this.slider.updateDraggable();
        } else if (!this.slider.options.draggable) {
          this.slider.options.draggable = true;
          this.slider.updateDraggable();
        }
      }
    },
    render() {
      return (
        <div class={styles.slider}>
          <div class={styles.sliderLayout} ref="slider">
            {this.$slots.slides}
          </div>

          {this.hasThumbs && (
            <div class={styles.sliderThumbs} ref="thumbs">
              {this.$slots.thumbs}
            </div>
          )}
        </div>
      );
    }
  });
