import { ObsVisibilityOptions } from './observer-visibility';

export const OBSERVER_VISIBILITY_CONFIG: ObsVisibilityOptions = {
  lazyLoad: {
    intersection: {
      root: null,
      rootMargin: '1100px',
      threshold: 0,
    },
    throttle: 50,
    once: true,
  },
  half: {
    once: true,
    intersection: {
      threshold: 0.5,
    },
  },
  all: {
    once: true,
    intersection: {
      threshold: 1,
    },
  },
  most: {
    intersection: {
      threshold: 0.75,
    },
  },
  custom: {
    intersection: {
      threshold: 1,
    },
  },
};
