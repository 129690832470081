import { guardZeroNumber } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBaseBlock, JtnUiBtn, JtnUiWidgetItem } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';

import { tsBaseBlockFunctionalityMixin } from '../../mixins';

import styles from './do-block.styles.scss?module';

type DoItem = {
  id: number;
  title: string;
  url: string;
  cost: number;
  image: string;
};

type DoParams = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  button_title?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  button_link?: string;
  title?: string;
  link?: string;
};

type BtnData = {
  text?: string;
  url?: string;
};

type ComponentData = {
  blockData: {
    params: DoParams;
    data: DoItem[];
  };
};

const REACH_GOAL_FIELD_TYPE = 'Объявления (блок)';
const REACH_GOAL_NAME = 'clickDOBlock';

export default componentFactoryOf()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .create({
    name: 'DoBlock',
    data(): ComponentData {
      return {
        blockData: {
          params: {},
          data: []
        }
      };
    },
    computed: {
      items(): DoItem[] {
        return this.blockData.data;
      },
      params(): DoParams {
        return this.blockData.params;
      },
      btnData(): BtnData | null {
        return this.blockData.params.button_title
          ? {
              text: this.blockData.params.button_title,
              url: this.blockData.params.button_link
            }
          : null;
      },
      canRender(): boolean {
        return this.$_BaseBlockFuncMixin_canRender;
      }
    },
    methods: {
      formatPrice(price: number) {
        return `${price.toLocaleString('ru-RU')} ₽`;
      },
      sendNewFormatReachGoal({
        goalName,
        prop1,
        prop2
      }: {
        goalName: string;
        prop1?: string;
        prop2?: string;
      }) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Объявления',
          actionType: goalName === 'View' ? 'Просмотр' : 'Клик',
          goalName: `${goalName}DOBlock`,
          prop1,
          prop2
        });
      },
      clickToHeader() {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          REACH_GOAL_FIELD_TYPE,
          'Объявления (заголовок)',
          REACH_GOAL_NAME,
          true
        );
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: 'Заголовок'
        });
      },
      clickToAdd() {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          REACH_GOAL_FIELD_TYPE,
          'Добавить объявление',
          REACH_GOAL_NAME,
          true
        );
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: 'Добавить объявление'
        });
      },
      clickToTarget(id: number, place: number) {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          REACH_GOAL_FIELD_TYPE,
          'Переход на объявление',
          REACH_GOAL_NAME,
          true
        );
        this.sendNewFormatReachGoal({
          goalName: 'Click',
          prop1: `${id}`,
          prop2: `${place}`
        });
      },
      onVisibleBlock() {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          REACH_GOAL_FIELD_TYPE,
          'Просмотр',
          'viewDOBlock',
          true
        );
        this.sendNewFormatReachGoal({
          goalName: 'View'
        });
      },
      async componentShown() {
        try {
          const res = await this.store.newsApiClient.getDoBlock({
            regionId: this.store.regionId
          });
          this.blockData = res.data;

          this.$_BaseBlockFuncMixin_sendReachGoalFromApi(
            this.$_BaseBlockFuncMixin_generalReachGoals
          );
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.obsVisibilityOptions}
          class={[styles.doBlock, styles[this.componentVisible ? 'visible' : '']]}
        >
          {guardZeroNumber(this.items.length) && (
            <JtnUiBaseBlock
              v-observe-visibility={this.obsHalfOptions}
              header={this.params.title}
              url={this.params.link}
              dataTestHeader="do_block"
              onClickToHeader={() => this.clickToHeader()}
            >
              {this.canRender && [
                <div class={styles.itemsList}>
                  {this.items.map((item, index) => (
                    <JtnUiWidgetItem
                      class={styles.doItem}
                      key={item.id}
                      title={item.title}
                      header={this.$_BaseBlockFuncMixin_reduceTitle(item.title)}
                      subtitle={this.formatPrice(item.cost)}
                      url={item.url}
                      target="_blank"
                      image={{
                        url: item.image
                      }}
                      onClick={() => this.clickToTarget(item.id, index + 1)}
                    />
                  ))}
                </div>,

                <JtnUiBtn
                  class={styles.button}
                  href={this.btnData?.url}
                  target="_blank"
                  nativeOnClick={() => this.clickToAdd()}
                >
                  {this.btnData?.text}
                </JtnUiBtn>
              ]}
            </JtnUiBaseBlock>
          )}
        </div>
      );
    }
  });
