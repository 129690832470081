import { injectScript } from '@smh/utils/dom';
import { guardUnspecified } from '@smh/utils/guards';

import type {
  BlockPlaceRepositoryConfig,
  ISparrowBlockAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';

import type { BlockUseCaseConfig, IBlockUseCase } from '../blocks';
import { BlockUseCase } from '../blocks';

type DeviceType = 'desktop' | 'laptop' | 'tablet' | 'mobile';

export const SPARROW_BLOCK_USE_CASE_KEY = 'sparrow_block_use_case_key';

export interface ISparrowBlockStore {
  deviceInfo: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
}

export type SparrowBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    store: ISparrowBlockStore;
    analyticsService: ISparrowBlockAnalyticsService;
  };
};

export interface ISparrowBlockUseCase extends IBlockUseCase {
  inject(devices: DeviceType[]): void;
  getWidgetScript(input: { key: string; sid: number }): void;
  processViewAdd(): void;
  processClickAdd(): void;
}

export class SparrowBlockUseCase extends BlockUseCase implements ISparrowBlockUseCase {
  private readonly _src = 'https://widget.sparrow.ru/js/embed.js';
  private readonly _store: ISparrowBlockStore;
  private readonly _analyticsService: ISparrowBlockAnalyticsService;
  constructor(config: SparrowBlockUseCaseConfig) {
    super(config);
    const {
      deps: { store, analyticsService },
    } = config;
    this._store = store;
    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processViewAdd() {
    this._analyticsService.sendEventBlockShown();
  }
  public processClickAdd() {
    this._analyticsService.sendEventBlockClick();
  }
  public getWidgetScript(input: { key: string; sid: number }) {
    const { key, sid } = input;
    return `
      <script data-key="${key}">
        (function(w, a) {
            (w[a] = w[a] || []).push({
                'script_key': '${key}',
                'settings': {
                    'sid': ${sid}
                }
            });
            if(window['_Sparrow_embed']){
              window['_Sparrow_embed'].initWidgets();
            }
        })(window, '_sparrow_widgets');
      </script>`;
  }

  public inject(devices: DeviceType[]): void {
    if (!this._getCanRenderOnThisDevice(devices)) {
      return;
    }
    const key = '_Sparrow_embed';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (guardUnspecified(window[key])) {
      return;
    }

    void injectScript(
      {
        src: this._src,
      },
      true,
    );
  }

  private _getCanRenderOnThisDevice(devices: DeviceType[]) {
    if (this._store.deviceInfo.isDesktop) {
      return devices.includes('desktop');
    }
    if (this._store.deviceInfo.isTablet) {
      return devices.includes('tablet');
    }
    if (this._store.deviceInfo.isMobile) {
      return devices.includes('mobile');
    }
    return true;
  }
}
