// @deprecated - Вместо этого миксина использовать ts версию '../base-block-functionality'
/* eslint-disable */
export const BaseBlockFunctionalityMixin = {
  data() {
    return {
      obsVisibilityOptions: {
        callback: this.visibilityChanged,
        once: true,
        intersection: {
          root: null,
          rootMargin: '1100px',
          threshold: 0
        },
        throttle: 50
      },
      obsHalfOptions: {
        callback: this.checkBlockVisible,
        once: true,
        intersection: {
          threshold: 0.5
        }
      },
      componentVisible: false,
      blockData: {
        params: {},
        data: [],
        reach_goal: null
      },
      retryCount: 0
    };
  },
  computed: {
    regionId() {
      return this.store.regionId;
    },
    newsApiClient() {
      return this.store.newsApiClient;
    },
    params() {
      return this.blockData.params;
    },
    isBrandingPage() {
      const { aTemplateBlock } = this.store.advModule;

      return (
        aTemplateBlock &&
        aTemplateBlock.data &&
        typeof aTemplateBlock.data.adfox_branding === 'string'
      );
    },
    $_BaseBlockFuncMixin_generalReachGoals() {
      if (this.blockData.reach_goal !== null && this.blockData.reach_goal.general) {
        return this.blockData.reach_goal.general;
      }

      return null;
    },
    canRender() {
      return this.items && this.items.length > 0 && this.componentVisible;
    }
  },
  methods: {
    async visibilityChanged(isVisible) {
      if (isVisible && this.componentVisible !== true) {
        this.retryCount++;
        try {
          await this.componentShown();
          this.componentVisible = true;
          this.retryCount = 0;
        } catch (err) {
          if (this.retryCount < 3) {
            await this.visibilityChanged(true);
          }
        }
      }
    },
    checkBlockVisible(isVisible) {
      if (isVisible) {
        this.onVisibleBlock();
      }
    },
    formatNumber(number) {
      return number.toLocaleString('ru-RU');
    },
    reduceTitle(title) {
      if (title.length <= 40) {
        return title;
      }

      const words = title.split(' ');
      let result = '';

      words.forEach(word => {
        if (word.length + result.length <= 36) {
          result += ` ${word}`;
        } else if (result[result.length - 1] === ',') {
          result = result.substring(0, result.length - 1);
        }
      });

      return `${result} ...`;
    },
    formatPrice(price) {
      return `${price.toLocaleString('ru-RU')} руб.`;
    },
    getblockType() {
      /**
       * desktopMinWidth: 1280 - минимальная ширина контента в трехколоночном виде (десктоп)
       */
      return window.innerWidth < 1280 || this.isBrandingPage
        ? 'Центральная колонка'
        : 'Правая колонка';
    },
    sendReachGoalFromApi(goalNames) {
      if (goalNames && goalNames.length > 0) {
        goalNames.forEach(name => {
          this.store.analyticsModule.sendReachGoal({ name });
        });
      }
    },
    sendNewReachGoal(fieldType, valueName, goalName, willDeprecate = false) {
      const blockType = this.getblockType();

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        willDeprecate
      });
    }
  }
};
