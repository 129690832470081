




import { Component, Vue, Watch } from 'vue-property-decorator';
import { useStore } from 'vuex-simple';
import { CONFIG_DATA } from './config';

import { RootModule } from '@jtnews/core';
import { Regions } from '@smh/projects/regions';

@Component({
  name: 'AdvInfinityFeed'
})
export default class AdvInfinityFeed extends Vue {
  store = useStore<RootModule>(this.$store);

  observerOptions = {
    root: null,
    rootMargin: '1100px',
    threshold: 0
  };
  observer: IntersectionObserver | null = null;
  isViewPort = false;

  @Watch('isViewPort')
  isViewPortChanged(value: boolean) {
    if (value) {
      this.init();
      this.observer?.disconnect();
    }
  }

  get isMobile() {
    return this.store.deviceInfo.isMobile;
  }

  get regionId() {
    return this.store.regionId;
  }

  get isRender() {
    return this.blockId !== '';
  }

  get blockId() {
    const data = CONFIG_DATA[this.regionId];
    const deviceType = this.isMobile ? 'mobile' : 'desktop';

    return data[deviceType] || '';
  }

  get partnerStatId() {
    return this.isMobile ? 110 : 100;
  }

  mounted() {
    this.initObserver();
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.isViewPort = entry.isIntersecting;
      });
    }, this.observerOptions);

    // На dev-средах бажит без проверки
    if (this.$el.tagName) {
      this.observer.observe(this.$el);
    }
  }

  init() {
    (window as any).yaContextCb = (window as any).yaContextCb || [];
    (window as any).yaContextCb.push(() => {
      (window as any).Ya.Context.AdvManager.render({
        blockId: this.blockId,
        renderTo: 'adv-infinity-feed',
        type: 'feed',
        statId: this.partnerStatId
      });
    });
  }
}
