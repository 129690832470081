











import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ObserverVisibility } from '@jtnews/shared/observer-visibility';
import type {
  ObsVisibilityOptions,
  ObsVisibilityHook,
} from '@jtnews/shared/observer-visibility';

@Component({
  name: 'UiObserveVisibility',
})
export default class UiObserveVisibility extends Vue {
  @Prop({
    type: String as () => ObsVisibilityHook,
    default: 'half',
  })
  hook: ObsVisibilityHook;

  @Prop({
    type: String,
    default: 'div',
  })
  tag: string;

  @Prop({
    type: Object,
    default: null,
  })
  config: ObsVisibilityOptions;

  observer: ObserverVisibility | null = null;

  onVisible(): void {
    this.$emit('visible');
  }

  created() {
    if (guardUnspecified(this.config)) {
      this.observer = new ObserverVisibility(this.hook, this.config);
      return;
    }
    this.observer = new ObserverVisibility(this.hook);
  }

  mounted() {
    this.observer?.event.subscribe(({ hook, value }) => {
      if (hook === this.hook && value) {
        this.onVisible();
      }
    });
  }

  beforeDestroy() {
    this.observer?.destroy();
  }
}
