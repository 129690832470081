const mixin = {
  decoder: {} as {
    methods: {
      entityDecode(html: string): string;
    };
  }
};

if (process.env.VUE_ENV === 'server') {
  const decode = require('ent/decode') as (value: string) => string; // eslint-disable-line @typescript-eslint/no-var-requires

  mixin.decoder = {
    methods: {
      entityDecode(html: string): string {
        return decode(html);
      }
    }
  };
} else {
  mixin.decoder = {
    methods: {
      entityDecode(html: string): string {
        const decoder = document.createElement('div');
        decoder.innerHTML = html;
        return decoder.textContent || '';
      }
    }
  };
}

export default mixin.decoder;
