import { Regions } from '@smh/projects/regions';

export const CONFIG_DATA = {
  [Regions.Yekaterinburg]: {
    desktop: 'R-A-447131-113',
    mobile: 'R-A-447131-112'
  },
  [Regions.Kurgan]: {
    desktop: 'R-A-447807-75',
    mobile: 'R-A-447807-76'
  },
  [Regions.Volgograd]: {
    desktop: 'R-A-447187-76',
    mobile: 'R-A-447187-77'
  },
  [Regions.Perm]: {
    desktop: 'R-A-447109-76',
    mobile: 'R-A-447109-77'
  },
  [Regions.Tyumen]: {
    desktop: 'R-A-447111-79',
    mobile: 'R-A-447111-80'
  },
  [Regions.KrasnodarRugion]: {
    desktop: 'R-A-451228-49',
    mobile: 'R-A-451228-50'
  },
  [Regions.Omsk]: {
    desktop: 'R-A-447031-73',
    mobile: 'R-A-447031-74'
  },
  [Regions.Kemerovo]: {
    desktop: 'R-A-451233-58',
    mobile: 'R-A-451233-59'
  },
  [Regions.Kazan]: {
    desktop: 'R-A-451220-58',
    mobile: 'R-A-451220-59'
  },
  [Regions.Mosk]: {
    desktop: 'R-A-1576891-57',
    mobile: 'R-A-1576891-58'
  },
  [Regions.Rostov]: {
    desktop: 'R-A-447095-81',
    mobile: 'R-A-447095-82'
  },
  [Regions.Krasnoyarsk]: {
    desktop: 'R-A-447175-75',
    mobile: 'R-A-447175-76'
  },
  [Regions.Yaroslavl]: {
    desktop: 'R-A-447116-77',
    mobile: 'R-A-447116-78'
  },
  [Regions.Arkhangelsk]: {
    desktop: 'R-A-447108-75',
    mobile: 'R-A-447108-76'
  },
  [Regions.Ufa]: {
    desktop: 'R-A-447185-78',
    mobile: 'R-A-447185-79'
  },
  [Regions.Chelyabinsk]: {
    desktop: 'R-A-447112-93',
    mobile: 'R-A-447112-94'
  },
  [Regions.NizhnyNovgorod]: {
    desktop: 'R-A-447176-105',
    mobile: 'R-A-447176-106'
  },
  [Regions.KhantyMansiysk]: {
    desktop: 'R-A-451226-62',
    mobile: 'R-A-451226-63'
  },
  [Regions.Sochi]: {
    desktop: 'R-A-2350385-37',
    mobile: 'R-A-2350385-38'
  },
  [Regions.Nsk]: {
    desktop: 'R-A-447169-101',
    mobile: 'R-A-447169-102'
  },
  [Regions.Irkutsk]: {
    desktop: 'R-A-1577768-52',
    mobile: 'R-A-1577768-53'
  },
  [Regions.Magnitogorsk]: {
    desktop: 'R-A-1577742-52',
    mobile: 'R-A-1577742-53'
  },
  [Regions.Chita]: {
    desktop: 'R-A-1578130-53',
    mobile: 'R-A-1578130-54'
  },
  [Regions.Tolyatty]: {
    desktop: 'R-A-453459-47',
    mobile: 'R-A-453459-48'
  },
  [Regions.Samara]: {
    desktop: 'R-A-447110-81',
    mobile: 'R-A-447110-82'
  },
  [Regions.Barnaul]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.VelikiiNovgorod]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Vologda]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Voronezh]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Izhevsk]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Kirov]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Lipetsk]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Murmansk]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Orenburg]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Pskov]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Ryazan]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.YamaloNenetsAutonomousArea]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Saratov]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Stavropol]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Sterlitamak]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Tambov]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Tomsk]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Tula]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Yakutsk]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Vladivostok]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  },
  [Regions.Ulyanovsk]: {
    desktop: 'R-A-739713-68',
    mobile: 'R-A-739713-69'
  }
};
