





import { guardUnspecified } from '@smh/utils/guards';
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

import { SPARROW_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type { ISparrowBlockUseCase } from '@jtnews/shared/seedwork/frontend/application';
import { UiObserveVisibility } from '@jtnews/shared/ui';

type DeviceType = 'desktop' | 'mobile' | 'tablet';

@Component({
  name: 'SparrowBlock',
  components: {
    UiObserveVisibility,
  },
})
export default class SparrowBlock extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  keyDesktop: string;

  @Prop({
    type: String,
    default: '',
  })
  keyMobile: string;

  @Prop({
    type: Number,
    default: 0,
  })
  sid: number;

  @Prop({
    type: Array,
    default: () => [],
  })
  devices: DeviceType[];
  @Prop({
    type: Boolean,
    required: true,
  })
  isMobile: boolean;

  @Inject(SPARROW_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: ISparrowBlockUseCase;

  private readonly _disposables: CallableFunction[] = [];

  get key() {
    return this.isMobile ? this.keyMobile : this.keyDesktop;
  }

  get widgetScript() {
    return this._useCase.getWidgetScript({ key: this.key, sid: this.sid });
  }

  processViewAdd() {
    this._useCase.processViewAdd();
  }

  processClickAdd() {
    this._useCase.processClickAdd();
  }

  handleBlur() {
    setTimeout(() => {
      const { activeElement } = document;

      if (
        guardUnspecified(activeElement) &&
        activeElement.tagName === 'IFRAME' &&
        activeElement.getAttribute('id')?.includes('SVK-Native')
      ) {
        this.processClickAdd();
      }
    });
  }

  mounted() {
    this._useCase.inject(this.devices);

    const handleBlur = this.handleBlur.bind(this);
    window.addEventListener('blur', handleBlur);

    this._disposables.push(() => window.removeEventListener('blur', handleBlur));
  }

  beforeDestroy() {
    this._disposables.forEach((disposable) => void disposable());
  }
}
