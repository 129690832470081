import { componentFactoryOf } from 'vue-tsx-support';

import { AspectRatio, JtnUiImage } from '@jtnews/jtn-ui';

import styles from './auto-slider-item.styles.scss?module';

interface Events {
  onLinkClicked: void;
}

export default componentFactoryOf<Events>().create({
  name: 'AutoSliderItem',
  props: {
    url: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    }
  },
  methods: {
    linkClick() {
      this.$emit('linkClicked');
    }
  },
  render() {
    return (
      <div class={styles.autoSlide}>
        <a
          href={this.url}
          target="_blank"
          class={styles.imgWrap}
          onClick={() => this.linkClick()}
        >
          <JtnUiImage
            class={styles.img}
            src={this.image}
            alt="фото автомобиля"
            aspectRatio={AspectRatio.Wide}
          />
        </a>
        <a
          href={this.url}
          target="_blank"
          class={styles.title}
          onClick={() => this.linkClick()}
        >
          {this.title}
        </a>
        <a
          href={this.url}
          target="_blank"
          class={styles.description}
          domPropsInnerHTML={this.description}
          onClick={() => this.linkClick()}
        />
        <a
          href={this.url}
          target="_blank"
          class={styles.price}
          onClick={() => this.linkClick()}
        >
          {this.price}
        </a>
      </div>
    );
  }
});
