/* eslint-disable */

import Vue from 'vue';
import { component } from 'vue-tsx-support';
import { useStore } from 'vuex-simple';

import { RootModule } from '@jtnews/store';

import { ObsVisibilityOptions } from '../../utils';
import { guardUnspecified } from '@portal/utils/util-guards';

interface GoalValueParams {
  [prop: string]: Record<string, GoalValueParams | string> | string;
}

type ValueParams = string | number | string[] | Record<string, string | GoalValueParams>;

type ReachGoalFromApiType = {
  [key: string]: string[];
};

type BlockData = {
  params: Record<string, unknown>;
  data: unknown[] | null;
  reach_goal: ReachGoalFromApiType | null;
};

type ObserverVisibilityCallback = Vue | null;

type ComponentData = {
  obsVisibilityOptions: ObsVisibilityOptions['custom'] & {
    callback: ObserverVisibilityCallback;
  };
  obsHalfOptions: ObsVisibilityOptions['half'] & { callback: ObserverVisibilityCallback };
  componentVisible: boolean;
  blockData: BlockData;
  retryCount: number;
  store: RootModule;
};

const MIN_WIDTH_DESKTOP = 1280;

export const tsBaseBlockFunctionalityMixin = component({
  data(): ComponentData {
    return {
      store: useStore<RootModule>(this.$store),
      obsVisibilityOptions: {
        callback: this
          .$_BaseBlockFuncMixin_visibilityChanged as ObserverVisibilityCallback,
        once: true,
        intersection: {
          root: null,
          rootMargin: '1100px',
          threshold: 0
        },
        throttle: 50
      },
      obsHalfOptions: {
        callback: this
          .$_BaseBlockFuncMixin_checkBlockVisible as ObserverVisibilityCallback,
        once: true,
        intersection: {
          threshold: 0.5
        }
      },
      componentVisible: false,
      blockData: {
        params: {},
        data: [],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        reach_goal: null
      },
      retryCount: 0
    };
  },
  computed: {
    $_BaseBlockFuncMixin_regionId(): number {
      return this.store.regionId;
    },
    $_BaseBlockFuncMixin_newsApiClient(): unknown {
      return this.store.newsApiClient;
    },
    $_BaseBlockFuncMixin_params(): BlockData['params'] {
      return this.blockData.params;
    },
    $_BaseBlockFuncMixin_generalReachGoals(): string[] | null {
      return this.blockData.reach_goal?.general || null;
    },
    $_BaseBlockFuncMixin_canRender(): boolean {
      return this.items?.length > 0 && this.componentVisible;
    }
  },
  methods: {
    async $_BaseBlockFuncMixin_visibilityChanged(isVisible: boolean): Promise<void> {
      if (isVisible && !this.componentVisible) {
        this.retryCount++;
        try {
          await this.componentShown();
          this.componentVisible = true;
          this.retryCount = 0;
        } catch (err) {
          if (this.retryCount < 3) {
            await this.$_BaseBlockFuncMixin_visibilityChanged(true);
          }
        }
      }
    },
    $_BaseBlockFuncMixin_checkBlockVisible(isVisible: boolean): void {
      if (isVisible) {
        this.onVisibleBlock();
      }
    },
    $_BaseBlockFuncMixin_reduceTitle(title: string): string {
      if (title.length <= 40) {
        return title;
      }

      const words = title.split(' ');
      let result = '';

      words.forEach(word => {
        if (word.length + result.length <= 36) {
          result += ` ${word}`;
        } else if (result[result.length - 1] === ',') {
          result = result.substring(0, result.length - 1);
        }
      });

      return `${result} ...`;
    },
    $_BaseBlockFuncMixin_formatPrice(price: number): string {
      return `${price.toLocaleString('ru-RU')} руб.`;
    },
    $_BaseBlockFuncMixin_getblockType(): string {
      return window.innerWidth < MIN_WIDTH_DESKTOP
        ? 'Центральная колонка'
        : 'Правая колонка';
    },
    $_BaseBlockFuncMixin_sendReachGoalFromApi(goalNames: string[] | null) {
      if (Array.isArray(goalNames) && goalNames.length > 0) {
        goalNames.forEach(name => {
          this.store.analyticsModule.sendReachGoal({ name });
        });
      }
    },
    $_BaseBlockFuncMixin_sendNewReachGoal(
      fieldType: string,
      valueName: ValueParams,
      goalName: string,
      willDeprecate: boolean = false,
      block?: string
    ) {
      const blockType = guardUnspecified(block)
        ? block
        : this.$_BaseBlockFuncMixin_getblockType();

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        willDeprecate
      });
    }
  }
});

/* eslint-enable */
