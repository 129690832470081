import { componentFactoryOf } from 'vue-tsx-support';

import { AspectRatio, JtnUiImage } from '@jtnews/jtn-ui';

import {
  tsStoreMixin,
  injectStylesMixin,
  AutoBlockMixin,
  BaseBlockFunctionalityMixin
} from '../../mixins';

import styles from './auto-list.styles.scss?module';

interface Events {
  onLinkClicked: void;
}

type AutoSlideItem = {
  url: string;
  images: {
    image320x240: string;
  };
  mark: string;
  model: string;
  transmission: string;
  year: string;
  mileage: string;
  price: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(AutoBlockMixin)
  .mixin(BaseBlockFunctionalityMixin)
  .create({
    name: 'AutoList',
    props: {
      list: {
        type: Array as () => AutoSlideItem[],
        default: () => []
      }
    },
    computed: {
      firstItem(): AutoSlideItem | null {
        if (this.list.length > 0) {
          return this.list.slice(0, 1)[0];
        }
        return null;
      },
      itemList(): AutoSlideItem[] {
        return this.list.slice(1, 4);
      }
    },

    methods: {
      linkClick() {
        this.$emit('linkClicked');
      },
      getSlideTitle(slide: AutoSlideItem): string {
        return `${slide.mark} ${slide.model}`;
      },
      getSlideDescription(slide: AutoSlideItem): string {
        const description: string[] = [];

        if (slide.transmission) {
          description.push(slide.transmission);
        }
        if (slide.year) {
          description.push(slide.year);
        }
        if (slide.mileage) {
          description.push(`${slide.mileage}&nbsp;км`);
        }

        return description.join(', ');
      },
      getSlidePrice(slide: AutoSlideItem): string {
        return `${this.formatNumber(slide.price)} ₽`;
      }
    },
    render() {
      return (
        <div class={styles.autoList}>
          <div class={styles.first}>
            <a
              href={this.firstItem.url}
              target="_blank"
              class={styles.firstImgWrap}
              onClick={() => this.linkClick()}
            >
              <JtnUiImage
                class={styles.firstImg}
                src={this.firstItem.images.image320x240}
                alt="фото автомобиля"
              />
            </a>
            <a
              href={this.firstItem.url}
              target="_blank"
              class={styles.firstTitle}
              onClick={() => this.linkClick()}
            >
              {this.getSlideTitle(this.firstItem)}
            </a>
            <a
              href={this.firstItem.url}
              target="_blank"
              class={styles.firstDescription}
              domPropsInnerHTML={this.getSlideDescription(this.firstItem)}
              onClick={() => this.linkClick()}
            />
            <a
              href={this.firstItem.url}
              target="_blank"
              class={styles.firstPrice}
              onClick={() => this.linkClick()}
            >
              {this.getSlidePrice(this.firstItem)}
            </a>
          </div>
          <ul class={styles.list}>
            {this.itemList.map((item: AutoSlideItem) => (
              <li key={item.url} class={styles.listItem}>
                <a
                  href={item.url}
                  target="_blank"
                  class={styles.listTitle}
                  onClick={() => this.linkClick()}
                >
                  {this.getSlideTitle(item)}
                </a>
                <a
                  href={this.firstItem.url}
                  target="_blank"
                  class={styles.listPrice}
                  onClick={() => this.linkClick()}
                >
                  {this.getSlidePrice(item)}
                </a>
                <a
                  href={item.url}
                  target="_blank"
                  class={styles.listDescription}
                  domPropsInnerHTML={this.getSlideDescription(item)}
                  onClick={() => this.linkClick()}
                />
                <a
                  href={item.url}
                  target="_blank"
                  class={styles.listImgWrap}
                  onClick={() => this.linkClick()}
                >
                  <JtnUiImage
                    class={styles.listImg}
                    src={this.firstItem.images.image320x240}
                    width={104}
                    height={70}
                    alt="фото автомобиля"
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  });
