import type {
  BlockPlaceRepositoryConfig,
  IBlockPlaceRepository,
} from './block-place.repository';
import type { IReachGoalAnalytics } from './reach-goal-analytics';

export type AnalyticsServiceConfig = {
  pageName: string;
  deps: {
    analytics: IReachGoalAnalytics;
    blockPlaceRepository: IBlockPlaceRepository;
  };
};

export interface IAnalyticsService {
  setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig): void;
}

export class AnalyticsService implements IAnalyticsService {
  protected pageName: string;
  protected analytics: IReachGoalAnalytics;
  protected blockPlaceRepository: IBlockPlaceRepository;
  private _defaultBlockPlaceConfig: BlockPlaceRepositoryConfig;
  constructor(config: AnalyticsServiceConfig) {
    const {
      pageName,
      deps: { analytics, blockPlaceRepository },
    } = config;
    this.pageName = pageName;
    this.analytics = analytics;
    this.blockPlaceRepository = blockPlaceRepository;
  }

  protected get blockPlace() {
    return this.blockPlaceRepository.get(this._defaultBlockPlaceConfig);
  }

  setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._defaultBlockPlaceConfig = blockPlaceConfig;
  }
}
