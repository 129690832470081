import { guardUnspecified } from '@portal/utils/util-guards';

import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';
import type {
  IBlockPlaceRepository,
  BlockPlaceRepositoryConfig,
} from '@jtnews/shared/seedwork/frontend/domain';

import { PageBreakpoints } from './page-breakpoints';

export const BLOCK_TYPE_REPOSITORY_KEY = 'block_type_repository_key';

export class BlockPlaceRepository implements IBlockPlaceRepository {
  constructor(private readonly _defaultPlace: AnalyticsBlockPlace) {}

  get(blockPlaceConfig?: BlockPlaceRepositoryConfig): AnalyticsBlockPlace {
    if (guardUnspecified(blockPlaceConfig)) {
      const { defaultPlace, ignoreBreakpoints } = blockPlaceConfig;

      if (ignoreBreakpoints) {
        return defaultPlace;
      }

      return window.innerWidth < PageBreakpoints.Desktop
        ? AnalyticsBlockPlace.Central
        : defaultPlace;
    }
    return this._defaultPlace;
  }
}
