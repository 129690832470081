import { isAbsoluteUrl } from '@portal/utils/util-url';

type ParamsUrl = Record<string, string>;

export const addUrlParams = (url: string, params: ParamsUrl = {}): string => {
  const absoluteUrl = isAbsoluteUrl(url) ? url : `http://ngs.ru/${url}`;
  const queryStringStart = new URL(absoluteUrl).search === '' ? '?' : '&';

  const queryString = Object.entries(params)
    .map((entry) => `${entry[0]}=${entry[1]}`)
    .join('&');
  return `${url}${queryStringStart}${queryString}`;
};
