import { guardZeroNumber } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBtn } from '@jtnews/jtn-ui';

import {
  tsStoreMixin,
  injectStylesMixin,
  AutoBlockMixin,
  tsBaseBlockFunctionalityMixin
} from '../../mixins';
import { AutoSliderItem } from '../auto-slider-item';
import { Slider } from '../slider';

import styles from './auto-slider.styles.scss?module';

interface Events {
  onSwipe: void;
  onLinkClicked: void;
}

type ComponentData = {
  sliderRendered: boolean;
  isBeginning: boolean;
  isEnd: boolean;
  currentGroup: number;
  slideWidth: number;
  sliderHeight: number;
  swipeDirection: 'Вправо' | 'Влево';
};

type AutoSlideItem = {
  url: string;
  images: {
    image320x240: string;
  };
  mark: string;
  model: string;
  transmission: string;
  year: string;
  mileage: string;
  price: string;
};

const SLIDE_GAP = 30;
const SLIDE_PADDING = 42;
const SLIDE_PADDING_LAPTOP = 90;
const SLIDE_PADDING_DESKTOP = 66;
const SLIDES_IN_SCREEN = 2;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(AutoBlockMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .create({
    name: 'AutoSlider',
    props: {
      items: {
        type: Array as () => AutoSlideItem[],
        default: []
      },
      isDesktop: {
        type: Boolean,
        default: false
      },
      isLaptop: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        sliderRendered: false,
        isBeginning: true,
        isEnd: false,
        currentGroup: 0,
        slideWidth: 0,
        sliderHeight: 0,
        swipeDirection: 'Влево'
      };
    },
    computed: {
      lastSlideIndex(): number {
        return this.items.length - 1;
      },
      isShowFirstGroup(): boolean {
        return this.currentGroup === 0;
      },
      isShowLastGroup(): boolean {
        return this.currentGroup === this.lastSlideIndex - 1;
      }
    },
    mounted() {
      this.getSlideWidth();
      this.getSliderHeight();
    },
    methods: {
      formatNumber(number: number): string {
        return number.toLocaleString('ru-RU');
      },
      sliderReady() {
        this.sliderRendered = true;
        this.updateSliderButtons();
      },
      getSlideWidth(): void {
        const containerWidth = (this.$refs?.sliderWrapper as HTMLElement).offsetWidth;

        this.slideWidth = Math.floor((containerWidth - SLIDE_GAP) / SLIDES_IN_SCREEN);
      },
      getSliderHeight(): void {
        let maxHeight = 0;

        const slides: Vue[] = this.items.map((item, index) => {
          return this.$refs[`slide-${index}`] as Vue;
        });

        slides.forEach(item => {
          const { height } = item.$el.getBoundingClientRect();
          if (height > maxHeight) {
            maxHeight = height;
          }
        });

        let padding = SLIDE_PADDING;

        if (this.isDesktop) {
          padding = SLIDE_PADDING_DESKTOP;
        }
        if (this.isLaptop) {
          padding = SLIDE_PADDING_LAPTOP;
        }

        this.sliderHeight = maxHeight + padding;
      },
      getSlideTitle(slide: AutoSlideItem): string {
        return `${slide.mark} ${slide.model}`;
      },
      getSlideDescription(slide: AutoSlideItem): string {
        const description: string[] = [];

        if (slide.transmission) {
          description.push(slide.transmission);
        }
        if (slide.year) {
          description.push(slide.year);
        }
        if (slide.mileage) {
          description.push(`${slide.mileage}&nbsp;км`);
        }

        return description.join(', ');
      },
      getSlidePrice(slide: AutoSlideItem): string {
        return `${this.formatNumber(Number(slide.price))} ₽`;
      },
      prevSlide() {
        if (!this.isShowFirstGroup) {
          this.currentGroup = this.currentGroup - 1;
          this.swipeDirection = 'Влево';
        }
      },
      nextSlide() {
        if (!this.isShowLastGroup) {
          this.currentGroup = this.currentGroup + 1;
          this.swipeDirection = 'Вправо';
        }
      },
      setGroup(groupIndex: number) {
        this.currentGroup = groupIndex;
        this.updateSliderButtons(groupIndex);
        this.$emit('swipe', this.swipeDirection);
      },
      updateSliderButtons(groupIndex = 0) {
        this.isBeginning = groupIndex === 0;
        this.isEnd = groupIndex === this.lastSlideIndex;
      },
      linkClick() {
        this.$emit('linkClicked');
      }
    },
    render() {
      const sliderStyle = {
        width: `${this.slideWidth}px`,
        marginRight: `${SLIDE_GAP}px`,
        height: guardZeroNumber(this.sliderHeight) ? `${this.sliderHeight}px` : 'auto'
      };
      const sliderWrapperStyle = { height: `${this.sliderHeight}px` };

      return (
        <div ref="sliderWrapper" class={styles.slideList} style={sliderWrapperStyle}>
          <Slider
            ref="slider"
            currentGroup={this.currentGroup}
            onSlideChanged={this.setGroup}
            onReady={this.sliderReady}
          >
            <template slot="slides">
              {this.items.map((item: AutoSlideItem, index: number) => (
                <AutoSliderItem
                  key={item.url}
                  ref={`slide-${index}`}
                  style={sliderStyle}
                  url={this.setUtmData(item.url)}
                  image={item.images.image320x240}
                  title={this.getSlideTitle(item)}
                  description={this.getSlideDescription(item)}
                  price={this.getSlidePrice(item)}
                  onLinkClicked={() => this.linkClick()}
                />
              ))}
            </template>
          </Slider>

          <JtnUiBtn
            theme="transparentDark"
            isDisabled={this.isShowFirstGroup}
            isRounded
            class={styles.btn}
            onClick={() => this.prevSlide()}
          >
            <template slot="left">
              <svg width="24" height="24">
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-arrow-line-left"></use>
              </svg>
            </template>
          </JtnUiBtn>

          <JtnUiBtn
            theme="transparentDark"
            isDisabled={this.isShowLastGroup}
            isRounded
            class={styles.btn}
            onClick={() => this.nextSlide()}
          >
            <template slot="left">
              <svg width="24" height="24">
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-line-right"></use>
              </svg>
            </template>
          </JtnUiBtn>
        </div>
      );
    }
  });
