/* eslint-disable */
import { PagesType } from '@jtnews/shared/data';

export const AutoBlockMixin = {
  computed: {
    isMobile() {
      return this.store.deviceInfo.isMobile;
    },
    isTablet() {
      return this.store.deviceInfo.isTablet;
    },
    isDesktop() {
      return this.store.deviceInfo.isDesktop;
    },
    isMobilePhone() {
      return this.isMobile && !this.isTablet;
    },
    pageTypeForUtm() {
      const pageType = this.store.pageType;

      if (pageType === PagesType.Main) {
        return 'main';
      }

      if (pageType === PagesType.Record || pageType === PagesType.Comments) {
        return 'news';
      }

      return 'other';
    }
  },
  methods: {
    setUtmData(url) {
      const place = 'widget';
      let pageType = this.pageTypeForUtm;

      if (this.position === 'right-column') {
        pageType += '-right';
      }

      if (this.isInread) {
        pageType = 'news_inread';
      }

      return url.replace('#PLACE#', place).replace('#PAGE#', pageType);
    }
  }
};
