<template>
  <div class="right-column">
    <template v-for="(blockItem, index) of rightColumnBlocks">
      <component
        v-if="isAdvBlock(blockItem.type)"
        v-bind="bindAdvComponent"
        :key="advKey(blockItem)"
        :adv="blockItem"
        :index="isScroogeTest ? index : undefined"
      />

      <top-records-block
        v-if="blockItem.type === 'top_news'"
        :key="blockItem.type + index"
        :wideMode="wideModeTopRecord"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <RecommendationsBlock
        v-if="blockItem.type === 'recommendation'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <auto-block
        v-if="blockItem.type === 'avito_auto'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        :devices="getDevices(blockItem)"
        position="center-column"
        type="Avito"
      />

      <opinions-block
        v-if="blockItem.type === 'opinions'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <DoBlock
        v-if="blockItem.type === 'do'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <love-block
        v-if="blockItem.type === 'love'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <subscriptions
        v-if="blockItem.type === 'subscribe'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        position="right-column"
      />

      <Smi2Block
        v-if="blockItem.type === 'smi2'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        :partnerId="blockItem.data.id"
        :devices="getDevices(blockItem)"
      />

      <SparrowBlock
        v-if="blockItem.type === 'sparrow'"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        :is-mobile="isMobile"
        :keyDesktop="blockItem.data.dataKeyDesktop"
        :keyMobile="blockItem.data.dataKeyMobile"
        :sid="blockItem.data.sid"
        :devices="getDevices(blockItem)"
      />
    </template>
    <AdvInfinityFeed v-if="isMobile && (isCommentsPage || isRecordPage)" />
  </div>
</template>
<script>
import { PAGE_NAME, PagesType } from '@jtnews/shared/data';
import {
  SPARROW_BLOCK_USE_CASE_KEY,
  SparrowBlockUseCase,
  SMI2_BLOCK_USE_CASE_KEY,
  Smi2BlockUseCase
} from '@jtnews/shared/seedwork/frontend/application';
import {
  SparrowBlockAnalyticsService,
  Smi2BlockAnalyticsService,
  AnalyticsBlockPlace
} from '@jtnews/shared/seedwork/frontend/domain';
import { SparrowBlock, Smi2Block } from '@jtnews/shared/seedwork/frontend/features';
import { BlockPlaceRepository } from '@jtnews/shared/seedwork/frontend/infrastructure';
import { Subject } from 'rxjs';
import { StoreMixin } from '../../../shared/mixins';

import { Adv, AdvCreative, AdvInfinityFeed } from '../../../shared/components/_adv';
import { TopRecordsBlock } from '../../../shared/components/top-records-block/';
import { RecommendationsBlock } from '../../../shared/components/recommendations-block';
import { AutoBlock } from '../../../shared/components/auto-block';
import { OpinionsBlock } from '../../../shared/components/opinions-block';
import { DoBlock } from '../../../shared/components/do-block';
import { LoveBlock } from '../../../shared/components/love-block';
import { Subscriptions } from '../../../features/record/containers/subscriptions';

export default {
  name: 'RightColumn',
  components: {
    Adv,
    AdvCreative,
    AdvInfinityFeed,
    TopRecordsBlock,
    RecommendationsBlock,
    AutoBlock,
    OpinionsBlock,
    DoBlock,
    LoveBlock,
    Subscriptions,
    Smi2Block,
    SparrowBlock
  },
  mixins: [StoreMixin],
  provide() {
    return {
      [SPARROW_BLOCK_USE_CASE_KEY]: new SparrowBlockUseCase({
        deps: {
          store: this.store,
          analyticsService: new SparrowBlockAnalyticsService({
            pageName: this.pageName,
            deps: {
              analytics: this.analyticsService,
              blockPlaceRepository: this.blockPlaceRepository
            }
          })
        }
      }),
      [SMI2_BLOCK_USE_CASE_KEY]: new Smi2BlockUseCase({
        deps: {
          store: this.store,
          analyticsService: new Smi2BlockAnalyticsService({
            pageName: this.pageName,
            deps: {
              analytics: this.analyticsService,
              blockPlaceRepository: this.blockPlaceRepository
            }
          })
        }
      })
    };
  },
  inject: {
    analyticsService: {
      from: 'analyticsService'
    }
  },
  computed: {
    dataFetching() {
      return this.store.dataFetching;
    },
    pageName() {
      return PAGE_NAME[this.pageType];
    },
    blockPlaceRepository() {
      return new BlockPlaceRepository(AnalyticsBlockPlace.Right);
    },
    rightColumnBlocks() {
      return this.dataFetching ? [] : this.store.layoutModule.rightColumn;
    },
    isAdblock() {
      return this.store.isAdblock;
    },
    isDesktop() {
      return this.store.deviceInfo.isDesktop;
    },
    isMobile() {
      return this.store.deviceInfo.isMobile;
    },
    isRecordPage() {
      return this.store.pageType === PagesType.Record;
    },
    isCommentsPage() {
      return this.store.pageType === PagesType.Comments;
    },
    aTemplateBlock() {
      return this.store.advModule.aTemplateBlock;
    },
    isBrandingPage() {
      return (
        this.isDesktop &&
        !this.isAdblock &&
        typeof this.aTemplateBlock?.data?.adfox_branding === 'string'
      );
    },
    isLaptop() {
      return (
        typeof window !== 'undefined' &&
        window.innerWidth >= 900 &&
        window.innerWidth < 1280
      );
    },
    wideModeTopRecord() {
      return this.isBrandingPage || this.isLaptop;
    },
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    bindAdvComponent() {
      return {
        is: this.isScroogeTest ? 'AdvCreative' : 'Adv'
      };
    }
  },
  methods: {
    getDevices(block) {
      return Object.entries(block.settings.types).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, []);
    },
    advKey(item) {
      return item.settings.placeId;
    },
    isAdvBlock(type) {
      return type === 'adv' || type === 'top_right_advert' || type === 'offer_day_advert';
    },
    getClasses(classes, blockType) {
      if (classes !== undefined) {
        return {
          ...classes,
          [blockType]: true
        };
      }
      return null;
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'styles';

.right-column {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 0 16px;

  .hidden {
    display: none;
  }

  .block {
    margin-top: 12px;
    margin-bottom: 12px;

    @include on-min-resize($tablet) {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &:not(.hidden) {
      display: block;
    }
  }
}
</style>
