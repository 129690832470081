import { guardZeroNumber } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBaseBlock, JtnUiTopRecordsItem } from '@jtnews/jtn-ui';
import {
  injectStylesMixin,
  tsStoreMixin,
  tsBaseBlockFunctionalityMixin,
  EntityDecoderMixin
} from '@jtnews/shared';
import { addUrlParams } from '@jtnews/shared/news';

import styles from './top-records-block.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
    m => m.BlockedUserModal
  );

interface Events {
  onClickToHeader: Event;
}

type Item = {
  id: number;
  header: string;
  commentsCount: number;
  isCommentsAllowed: boolean;
  trend: 'up' | 'down';
  viewsCount: number;
  urls: {
    url: string;
    urlCanonical: string;
    urlComments: string;
  };
};

type NewFormatReachGoal = {
  goalName: string;
  actionType: string;
  prop1?: string;
  prop2?: string;
};

type ComponentData = {
  isBlockedUserModalOpened: boolean;
  isBlockedUserModalRendered: boolean;
};

export default componentFactoryOf<Events>()
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .mixin(EntityDecoderMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'TopRecordsBlock',
    props: {
      hasArrow: {
        type: Boolean,
        default: true
      },
      hasBorder: {
        type: Boolean,
        default: true
      }
    },
    data(): ComponentData {
      return {
        isBlockedUserModalOpened: false,
        isBlockedUserModalRendered: false
      };
    },
    computed: {
      items(): Item[] {
        return (this.blockData?.data as Item[] | null) || [];
      },
      newsApiClient() {
        return this.store.newsApiClient;
      },
      isBlockedUser() {
        return this.store.commonModule.isBlockedUser;
      }
    },
    methods: {
      async componentShown(): Promise<void> {
        try {
          const res = await this.newsApiClient.getTopRecordsBlock({
            regionId: this.$_BaseBlockFuncMixin_regionId
          });

          this.blockData = res.data;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      onVisibleBlock() {
        const ids = this.items.map((item: Item) => item.id).join(', ');

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'ТОП5 (блок)',
          'Просмотр',
          'viewTopPopularBlock',
          true
        );

        this.sendNewFormatReachGoal({
          actionType: 'Просмотр',
          goalName: 'ViewTOP5',
          prop1: ids
        });
      },
      sendNewFormatReachGoal({ actionType, goalName, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'ТОП 5',
          actionType,
          goalName,
          prop1,
          prop2
        });
      },
      clickedLink(value: string, id: number, place: string) {
        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'ТОП5 (блок)',
          { [value]: `${id}` },
          'clickTopPopularBlock',
          true
        );
        this.sendNewFormatReachGoal({
          actionType: 'Клик',
          goalName: 'ClickTOP5',
          prop1: `${id}`,
          prop2: place
        });
      },
      onDiscussClicked(event: Event, value: string, id: number, place: string) {
        this.clickedLink(value, id, place);

        if (this.isBlockedUser !== true) return;

        event.preventDefault();
        this.openBlockedUserModal();
      },
      getDiscussUrl(url: string): string {
        return addUrlParams(url, { discuss: '1' });
      },
      openBlockedUserModal() {
        this.isBlockedUserModalOpened = true;
        this.isBlockedUserModalRendered = true;
      }
    },
    render() {
      return (
        <noindex>
          <div v-observe-visibility={this.obsVisibilityOptions} class={styles.topRecords}>
            {guardZeroNumber(this.items.length) && (
              <JtnUiBaseBlock
                v-observe-visibility={this.obsHalfOptions}
                header={this.$_BaseBlockFuncMixin_params.title as string}
                dataTestHeader="top_news_block"
              >
                <div class={styles.list}>
                  {this.items.map((item: Item, index: number) => (
                    <JtnUiTopRecordsItem
                      class={styles.item}
                      commentsCount={item.commentsCount}
                      commentsUrl={item.urls.urlComments}
                      discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                      header={this.entityDecode(item.header)}
                      index={index + 1}
                      isCommentsAllowed={item.isCommentsAllowed}
                      key={item.id}
                      url={item.urls.url}
                      viewsCount={item.viewsCount}
                      onRecordClicked={() =>
                        this.clickedLink('Переход на материал', item.id, 'Материал')
                      }
                      onCommentsClicked={() =>
                        this.clickedLink('Переход на комментарии', item.id, 'Комментарий')
                      }
                      onDiscussClicked={(event: Event) =>
                        this.onDiscussClicked(
                          event,
                          'Переход на комментарии',
                          item.id,
                          'Комментарий'
                        )
                      }
                    />
                  ))}
                </div>
              </JtnUiBaseBlock>
            )}
          </div>
          {this.isBlockedUserModalRendered && (
            <BlockedUserModal vModel={this.isBlockedUserModalOpened} />
          )}
        </noindex>
      );
    }
  });
